import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const ReferenceCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    height: "525px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "blue";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "blue";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  default:
    return "black";
  }
};

export const PamacReferenceDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [referenceInfo, setReferenceInfo] = useState([]);

  const getReferenceInfo = (id) => {
    ApiService.get(`referenceCheck/${id}`)
      .then((res) => {
        setReferenceInfo(res?.data?.docs);
        // SetFileUplaod(data?.education?.reportUrl || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getReferenceInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (referenceInfo.length > 0) {
      onDataUpdate({
        referenceInfo,
        renderReferenceDetails: () => renderReferenceDetails(),
      });
    }
  }, [referenceInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderReferenceDetails = () => {
    return (
      <>
        <View wrap>
          <Text style={{ textAlign: "center", textDecoration: "underline" }}>
            Reference Verification
          </Text>
          {referenceInfo?.length > 0 && (
            <>
              {referenceInfo.map((referenceEvidence, index) => (
                <React.Fragment key={index}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 5,
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Employment verification:
                    </Text>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        Status:
                      </Text>
                      <View
                        style={{
                          backgroundColor: getStatusColor(
                            referenceEvidence?.reference?.status
                          ),
                          paddingVertical: 2,
                          paddingHorizontal: 10,
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            color: "black",
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {referenceEvidence?.reference?.status}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    key={index}
                    style={ReferenceCheckStyles.table}
                    wrap={false}
                  >
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Reference Details
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Information obtained
                      </Text>
                    </View>

                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name of the reference
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.fullName}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name of the company & Designation
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {
                          referenceEvidence?.reference
                            ?.companyNameAndDesignation
                        }
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Contact number
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        Telephone numbers: + 91
                        {referenceEvidence?.reference?.contactNumber}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Email id
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        Email ID:-{referenceEvidence?.reference?.email}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        How do you know the candidate and since how many years?
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.candidateYear}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Period of reporting
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.periodOfReporting}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Designation
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.designation}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Attitude towards work
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.attitudeTowards}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Duties & Responsibilities
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.duties}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Effective/ Non Effective
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.effective}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Ability to work under stress/ pressure
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.abilityToWork}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Communication Skills
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.communicationSkill}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Interpersonal Skills
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.interpersonalSkills}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Technical Skills
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.technicalSkill}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Leadership Skills(if applicable)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.leadershipSkill}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Professional Strengths
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.professionalStrength}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Professional weakness/ Areas of improvement
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.professionalWeakness}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Any personal issues affecting the job performance
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.personalIssues}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Attendance and Punctuality
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.attendance}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Honesty / Reliability
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.honesty}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Integrity issues
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.integrity}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Eligibility for rehire (If no , why)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.eligibilityRehire}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Performance Rating (On a scale of 1 to 10, 1 being the
                        lowest and 10 being the highest )
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.performanceRating}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Additional Comments
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.additionalComments}
                      </Text>
                    </View>
                  </View>
                  {/* Loop through and display each proof image */}
                  {referenceEvidence?.reference?.uploadProofUrl?.map(
                    (url, proofIndex) => (
                      <View
                        key={proofIndex}
                        style={ReferenceCheckStyles.centeredImageContainer}
                        wrap={false} // Ensure image stays on its own page
                        break={proofIndex !== 0} // Add page break after each image except the first one
                      >
                        {/* <Text
                          style={{
                            fontWeight: "bold",
                            margin: "17px 0 0 0",
                            textDecoration: "underline",
                            textAlign: "center",
                            display: "block",
                            marginTop: "50px",
                            marginBottom: "20px",
                          }}
                        >
                          Copy of summarization mail sent to{" "}
                          {referenceEvidence?.reference?.schoolName}
                        </Text> */}
                        <AsyncImage
                          fileUrl={url}
                          alt={`Reference evidence ${proofIndex + 1}`}
                        />
                      </View>
                    )
                  )}
                  <View break />
                </React.Fragment>
              ))}
            </>
          )}
        </View>
      </>
    );
  };
  return null;
};
