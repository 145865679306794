import React, { useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  Image,
  Page,
  Document,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";

import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import Gvs_logo from "../../../assets/images/GVS_logo.png";
import Gvs_footer from "../../../assets/images/GVS_footer_.png";
import { GvsEducationDetailCheck } from "./GvsEducationDetailCheck.jsx";
import { GvsEmploymentDetailCheck } from "./GvsEmploymentDetailCheck.jsx";
import { GvsReferenceDetailCheck } from "./GvsReferenceDetailCheck.jsx";
import { GvsAddressDetailCheck } from "./GvsAddressDetailCheck.jsx";
import { GvsCourtDetailCheck } from "./GvsCourtDetailCheck.jsx";
import timesBold from "../../../assets/Times-New-Roman/times-new-roman-bold.ttf";
import timesRegular from "../../../assets/Times-New-Roman/times-new-roman.ttf";
import { GvsRestrictionPage } from "./GvsRestrictionsPage.jsx";

Font.register({
  family: "Times New Roman",
  fonts: [
    { src: timesRegular }, // Regular Times New Roman
    { src: timesBold, fontWeight: "bold" }, // Bold Times New Roman
  ],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 75, // Increased top padding for header
    paddingBottom: 40, // Increased bottom padding for footer
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerContainer: {
    position: "absolute",
    top: 5,
    left: 40,
    right: 40,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 40,
    right: 40,
  },
  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  titleContainer: { flexDirection: "row", marginTop: 24 },

  logo: { width: 100, height: 90 },
  heading: {
    textAlign: "center",
    marginTop: 40,
    fontSize: 14,
    fontWeight: "bold",
    textDecoration: "underline",
    textDecorationColor: "black",
  },
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "left",
    padding: 5,
    fontSize: 10,
  },
  labelCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontWeight: 900,
    fontSize: 8,
  },
  synopsis: {
    textAlign: "center",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  synopsistableCell: {
    flex: 1, // Normal cell width
    border: "1px solid black",
    textAlign: "left",
    padding: 5,
    fontSize: 10,
  },
  footer: {
    position: "absolute",
    height: "43px",
    bottom: 30,
    left: 0,
    right: 0,
  },
});

const executiveSummaryStyles = StyleSheet.create({
  table: {
    // width: "full",
    height: "430px",
    // height: "100%",
    borderWidth: 0,
    // borderColor: "black",
    margin: 10,
  },
  header: {
    backgroundColor: "#cc0000",
    padding: 10,
  },
  headerText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    textDecoration: "underline",
    fontFamily: "Times New Roman",
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0,
    borderColor: "black",
  },
  col1: {
    flex: 2,
    padding: 5,
    borderRightWidth: 0,
    borderColor: "black",
    backgroundColor: "#c5d9eb",
  },
  col2: {
    flex: 2,
    padding: 5,
    borderRightWidth: 0,
    borderColor: "black",
    backgroundColor: "#c5d9eb",
  },
  col3: {
    flex: 1,
    padding: 5,
    borderRightWidth: 0,
    borderColor: "black",
    // alignItems: "center",
    backgroundColor: "#c5d9eb",
  },
  col4: {
    flex: 1,
    padding: 5,
    borderRightWidth: 0,
    borderColor: "black",
    backgroundColor: "#c5d9eb",
  },
  col5: {
    flex: 1,
    padding: 5,
    justifyContent: "center",
    // alignItems: "center",
    backgroundColor: "#c5d9eb",
  },
  colorBox: {
    width: 20,
    height: 10,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 5,
  },
  footerBox: {
    padding: 5,
    width: "18%",
  },
  footerText: {
    color: "black",
    textAlign: "center",
    fontWeight: "bold",
  },
  footerLegend: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 5,
  },
  footerLegendText: {
    fontSize: 10,
    textAlign: "center",
    width: "18%",
  },
});

const checksStyle = StyleSheet.create({
  checksContainer: {
    marginTop: "20px",
  },
});
const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const Header = () => (
  <View style={styles.headerContainer} fixed>
    <CompanyTitle />
  </View>
);
const Footer = () => (
  <View style={styles.footerContainer} fixed>
    <Image style={styles.footer} src={Gvs_footer} />
  </View>
);
const CompanyTitle = () => (
  <View style={styles.titleContainer} fixed>
    <View style={styles.spaceBetween}>
      <Image style={styles.logo} src={Gvs_logo} />
    </View>
  </View>
);

const CurrentDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const GeneralInfo = ({ verificationInfo }) => (
  <View style={styles.table}>
    {/* Row 1 */}
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: 900,
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        Applicant Name
      </Text>
      <Text style={styles.tableCell}>
        {`${verificationInfo?.userInfo?.firstName} ${verificationInfo?.userInfo?.lastName}`}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: "900",
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        Date of Allocation
      </Text>
      <Text style={styles?.tableCell}>
        {formatDate(verificationInfo?.requestedDate)}
      </Text>
    </View>

    {/* Row 2 */}
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: 900,
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        GVS Case ID
      </Text>
      <Text style={styles.tableCell}>
        {" "}
        {`${process.env.REACT_APP_REFERENCE_TITLE}-${verificationInfo?.case}`}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: 900,
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        Date of Delivery
      </Text>
      <Text style={styles.tableCell}>{CurrentDate()}</Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: 900,
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        Gender
      </Text>
      <Text style={styles.tableCell}>{verificationInfo?.userInfo?.gender}</Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: 900,
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        Date of Birth
      </Text>
      <Text style={styles.tableCell}>
        {formatDate(verificationInfo?.userInfo?.dateOfBirth)}
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: 900,
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        Client Name
      </Text>
      <Text style={styles.tableCell}>
        {verificationInfo?.companyInfo?.name}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: 900,
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        Client Case ID
      </Text>
      <Text style={styles.tableCell}>
        {verificationInfo?.companyInfo?.clientCaseId}
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "171px",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontWeight: 900,
          fontSize: 10,
          backgroundColor: "#c5d9eb",
        }}
      >
        Level of Check
      </Text>
      <Text
        style={{
          width: "100%",
          border: "0.5px solid black",
          textAlign: "left",
          padding: 5,
          fontSize: 10,
        }}
      >
        Standard
      </Text>
    </View>
  </View>
);

const ExecutiveSummary = ({
  educationData,
  experienceData,
  addressData,
  referenceData,
  courtData,
}) => {
  return (
    <>
      <View style={executiveSummaryStyles.header}>
        <Text style={executiveSummaryStyles.headerText}>EXECUTIVE SUMMARY</Text>
      </View>
      <View style={executiveSummaryStyles.table} wrap>
        {/* <View style={executiveSummaryStyles.row}>
        <Text> </Text>
      </View> */}
        <View style={executiveSummaryStyles.row}>
          <View
            style={[
              executiveSummaryStyles.col1,
              { backgroundColor: "#6d9eeb" },
            ]}
          >
            <Text>Check</Text>
          </View>
          <View
            style={[
              executiveSummaryStyles.col2,
              { backgroundColor: "#6d9eeb" },
            ]}
          >
            <Text>Description</Text>
          </View>
          <View
            style={[
              executiveSummaryStyles.col3,
              { backgroundColor: "#6d9eeb" },
            ]}
          >
            <Text>Verified</Text>
          </View>
          <View
            style={[
              executiveSummaryStyles.col4,
              { backgroundColor: "#6d9eeb" },
            ]}
          >
            <Text>Remarks</Text>
          </View>
          <View
            style={[
              executiveSummaryStyles.col5,
              { backgroundColor: "#6d9eeb" },
            ]}
          >
            <Text>Color Code</Text>
          </View>
        </View>
        {addressData?.addressInfo?.map((item, index) => (
          <TableRow
            key={index}
            check="Residential Address"
            description={item?.address?.typeOfAddress}
            verified={item?.address?.status === "Verified Clear" ? "Done" : "-"}
            remarks={item?.address?.status}
            colorCode={
              item?.address?.status === "Verified Clear"
                ? "Green"
                : item?.address?.status === "Major Discrepancy"
                  ? "Red"
                  : item?.address?.status === "Pending"
                    ? "Yellow"
                    : item?.address?.status === "Minor Discrepancy"
                      ? "Orange"
                      : item?.address?.status === "Inconclusive"
                        ? "Pink"
                        : "-"
            }
          />
        ))}
        <View>
          <Text> </Text>
        </View>
        {educationData?.educationInfo?.map((item, index) => (
          <TableRow
            key={index}
            check="Educational Qualification"
            description={item?.education?.qualification}
            verified={
              item?.education?.status === "Verified Clear" ? "Done" : "-"
            }
            remarks={item?.education?.status}
            colorCode={
              item?.education?.status === "Verified Clear"
                ? "Green"
                : item?.education?.status === "Major Discrepancy"
                  ? "Red"
                  : item?.education?.status === "Pending"
                    ? "Yellow"
                    : item?.education?.status === "Minor Discrepancy"
                      ? "Orange"
                      : item?.education?.status === "Inconclusive"
                        ? "Pink"
                        : "-"
            }
          />
        ))}
        <View>
          <Text> </Text>
        </View>
        {experienceData?.experienceInfo?.map((item, index) => (
          <TableRow
            key={index}
            check="Employment Check "
            description={item?.experience?.organizationName}
            verified={
              item?.experience?.status === "Verified Clear" ? "Done" : "-"
            }
            remarks={item?.experience?.status}
            colorCode={
              item?.experience?.status === "Verified Clear"
                ? "Green"
                : item?.experience?.status === "Major Discrepancy"
                  ? "Red"
                  : item?.experience?.status === "Pending"
                    ? "Yellow"
                    : item?.experience?.status === "Minor Discrepancy"
                      ? "Orange"
                      : item?.experience?.status === "Inconclusive"
                        ? "Pink"
                        : "-"
            }
          />
        ))}
        <View>
          <Text> </Text>
        </View>
        {courtData?.courtInfo?.map((item, index) => (
          <TableRow
            key={index}
            check="Court Records Check"
            description={item?.court?.typeOfAddress}
            verified={item?.court?.status === "Verified Clear" ? "Done" : "-"}
            remarks={item?.court?.status}
            colorCode={
              item?.court?.status === "Verified Clear"
                ? "Green"
                : item?.court?.status === "Major Discrepancy"
                  ? "Red"
                  : item?.court?.status === "Pending"
                    ? "Yellow"
                    : item?.court?.status === "Minor Discrepancy"
                      ? "Orange"
                      : item?.court?.status === "Inconclusive"
                        ? "Pink"
                        : "-"
            }
          />
        ))}
        <View>
          <Text> </Text>
        </View>
        {/* <TableRow
          check="Identity Check"
          description="AAdhaar Card"
          verified={true}
          remarks="Positive"
          colorCode="Green"
        />
        <TableRow
          check="Identity Check"
          description="Pan Card"
          verified={true}
          remarks="Positive"
          colorCode="Green"
        /> */}
        {referenceData?.referenceInfo?.map((item, index) => (
          <TableRow
            key={index}
            check="Professional Reference "
            description={item?.reference?.fullName}
            verified={
              item?.reference?.status === "Verified Clear" ? "Done" : "-"
            }
            remarks={item?.reference?.status}
            colorCode={
              item?.reference?.status === "Verified Clear"
                ? "Green"
                : item?.reference?.status === "Major Discrepancy"
                  ? "Red"
                  : item?.reference?.status === "Pending"
                    ? "Yellow"
                    : item?.reference?.status === "Minor Discrepancy"
                      ? "Orange"
                      : item?.reference?.status === "Inconclusive"
                        ? "Pink"
                        : "-"
            }
          />
        ))}
        <View>
          <Text> </Text>
        </View>
        {/* <TableRow
          check="Professional Reference 2"
          description="N/A"
          verified={false}
          remarks="-"
          colorCode="-"
        />
        <TableRow
          check="Professional Reference 3"
          description="N/A"
          verified={false}
          remarks="-"
          colorCode="-"
        />
        <TableRow
          check="Dual Employment Check"
          description="UAN"
          verified={true}
          remarks="Discrepancy"
          colorCode="Red"
        />
        <TableRow
          check="Credit Check"
          description="Cibil (Permanent Address)"
          verified={false}
          remarks="-"
          colorCode="-"
        /> */}
        <View style={executiveSummaryStyles.footer}>
          <View
            style={[
              executiveSummaryStyles.footerBox,
              { backgroundColor: "red" },
            ]}
          >
            <Text style={executiveSummaryStyles.footerText}>RED</Text>
          </View>
          <View
            style={[
              executiveSummaryStyles.footerBox,
              { backgroundColor: "yellow" },
            ]}
          >
            <Text style={executiveSummaryStyles.footerText}>YELLOW</Text>
          </View>
          <View
            style={[
              executiveSummaryStyles.footerBox,
              { backgroundColor: "green" },
            ]}
          >
            <Text style={executiveSummaryStyles.footerText}>GREEN</Text>
          </View>
          <View
            style={[
              executiveSummaryStyles.footerBox,
              { backgroundColor: "orange" },
            ]}
          >
            <Text style={executiveSummaryStyles.footerText}>Orange</Text>
          </View>
          <View
            style={[
              executiveSummaryStyles.footerBox,
              { backgroundColor: "pink" },
            ]}
          >
            <Text style={executiveSummaryStyles.footerText}>Pink</Text>
          </View>
        </View>
        <View style={executiveSummaryStyles.footerLegend}>
          <Text style={executiveSummaryStyles.footerLegendText}>
            Major Discrepancy
          </Text>
          <Text style={executiveSummaryStyles.footerLegendText}>Awaited</Text>
          <Text style={executiveSummaryStyles.footerLegendText}>
            Clear Report
          </Text>
          <Text style={executiveSummaryStyles.footerLegendText}>
            Minor Discrepancy
          </Text>
          <Text style={executiveSummaryStyles.footerLegendText}>
            Inconclusive
          </Text>
        </View>
      </View>
    </>
  );
};

const TableRow = ({ check, description, verified, remarks, colorCode }) => (
  <View style={executiveSummaryStyles.row}>
    <View style={executiveSummaryStyles.col1}>
      <Text>{check}</Text>
    </View>
    <View style={executiveSummaryStyles.col2}>
      <Text>{description}</Text>
    </View>
    <View style={executiveSummaryStyles.col3}>
      <Text>{verified}</Text>
    </View>
    <View style={executiveSummaryStyles.col4}>
      <Text>{remarks}</Text>
    </View>
    <View style={executiveSummaryStyles.col5}>
      <Text
        style={{
          textAlign: "center",
          color: "black",
          backgroundColor: colorCode.toLowerCase(),
          padding: 2,
          borderRadius: 8,
        }}
      >
        {colorCode}
      </Text>
    </View>
  </View>
);

const ReportDocument = ({
  // data,
  verificationInfo,
  selectedChecks,
  educationData,
  experienceData,
  referenceData,
  addressData,
  courtData,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header />
      <Text
        style={[
          styles.heading,
          {
            fontSize: 14,
            color: "red",
            fontFamily: "Times New Roman",
          },
        ]}
      >
        BACKGROUND VERIFICATION REPORT
      </Text>
      <GeneralInfo verificationInfo={verificationInfo} />
      <ExecutiveSummary
        addressData={addressData}
        educationData={educationData}
        experienceData={experienceData}
        courtData={courtData}
        referenceData={referenceData}
      />
      <Footer />
    </Page>

    {/* Education Details Page */}
    {selectedChecks?.education && educationData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle?.checksContainer} wrap={true}>
          {educationData?.renderEducationDetails()}
        </View>
        <Footer />
      </Page>
    )}

    {/* Employment Details Page */}
    {selectedChecks?.employment && experienceData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle?.checksContainer} wrap={true}>
          {experienceData?.renderExperienceDetails()}
        </View>
        <Footer />
      </Page>
    )}

    {/* Reference Details Page */}
    {selectedChecks?.reference && referenceData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle?.checksContainer} wrap={true}>
          {referenceData?.renderReferenceDetails()}
        </View>
        <Footer />
      </Page>
    )}

    {/* Address Details Page */}
    {selectedChecks?.address && addressData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle?.checksContainer} wrap={true}>
          {addressData?.renderAddressDetails()}
        </View>
        <Footer />
      </Page>
    )}

    {/* Court Details Page */}
    {selectedChecks?.court && courtData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle?.checksContainer} wrap={true}>
          {courtData?.renderCourtDetails()}
        </View>
        <Footer />
      </Page>
    )}

    {/* GVS Restriction Page */}
    {/* {selectedChecks?.gvsRestriction && ( */}
    <Page size="A4" style={styles.page} wrap={true}>
      <Header />
      <GvsRestrictionPage />
      <Footer />
    </Page>
  </Document>
);

export const GvsReport = () => {
  const [selectedChecks, setSelectedChecks] = useState({
    education: true,
    employment: true,
    reference: true,
    address: true,
    court: true,
  });
  const location = useLocation();
  const [isPreview, setIsPreview] = useState(false);
  const [verificationInfo, setVerificationInfo] = useState(null);
  const [educationData, setEducationData] = useState(null);
  const [experienceData, setExperienceData] = useState(null);
  const [referenceData, setReferenceData] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [courtData, setCourtData] = useState(null);
  const getUserVerificationReqInfo = async (userVerificationReqId) => {
    await ApiService.get(`userVerificationRequest/${userVerificationReqId}`)
      .then((res) => {
        setVerificationInfo(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    console.log("VerificationInfor>>>>", verificationInfo);
  }, [verificationInfo]);
  useEffect(() => {
    getUserVerificationReqInfo(location?.state?.id);
  }, [location?.state?.id]);
  const handleEducationDataUpdate = (data) => {
    setEducationData(data);
  };
  useEffect(() => {
    console.log("DTATTATATT>>>>>", educationData);
  }, [educationData]);
  const handleExperienceDataUpdate = (data) => {
    setExperienceData(data);
  };
  const handleReferenceDataUpdate = (data) => {
    setReferenceData(data);
  };
  const handleAddressDataUpdate = (data) => {
    setAddressData(data);
  };
  const handleCourtDataUpdate = (data) => {
    setCourtData(data);
  };
  const togglePreview = () => {
    setIsPreview(!isPreview);
  };
  return (
    <div>
      <h1>Generate Background Report</h1>
      {/* Options to select sections for interim report */}
      <div>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.education}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                education: !prev.education,
              }))
            }
          />{" "}
          Education
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.employment}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                employment: !prev.employment,
              }))
            }
          />{" "}
          Employment
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.court}
            onChange={() =>
              setSelectedChecks((prev) => ({ ...prev, court: !prev.court }))
            }
          />{" "}
          Court
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.address}
            onChange={() =>
              setSelectedChecks((prev) => ({ ...prev, address: !prev.address }))
            }
          />{" "}
          Address
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.reference}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                reference: !prev.reference,
              }))
            }
          />{" "}
          Reference
        </label>
      </div>
      <button onClick={togglePreview}>
        {isPreview ? "Close Preview" : "Preview Report"}
      </button>
      <GvsEducationDetailCheck onDataUpdate={handleEducationDataUpdate} />
      <GvsEmploymentDetailCheck onDataUpdate={handleExperienceDataUpdate} />
      <GvsReferenceDetailCheck onDataUpdate={handleReferenceDataUpdate} />
      <GvsAddressDetailCheck onDataUpdate={handleAddressDataUpdate} />
      <GvsCourtDetailCheck onDataUpdate={handleCourtDataUpdate} />
      {/* PDF Preview */}
      {isPreview && (
        <div style={{ height: "500px", marginTop: "20px" }}>
          <PDFViewer width="100%" height="500px">
            <ReportDocument
              //   data={sampleData}
              verificationInfo={verificationInfo}
              selectedChecks={selectedChecks}
              educationData={educationData}
              experienceData={experienceData}
              referenceData={referenceData}
              addressData={addressData}
              courtData={courtData}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default GvsReport;
