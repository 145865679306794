import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const EducationCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "blue";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "blue";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  default:
    return "black";
  }
};

export const PamacEducationDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [educationInfo, setEducationInfo] = useState([]);

  const getEducationInfo = (id) => {
    if (id) {
      ApiService.get(`educationCheck/${id}`)
        .then((res) => {
          setEducationInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getEducationInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (educationInfo.length > 0) {
      onDataUpdate({
        educationInfo,
        renderEducationDetails: () => renderEducationDetails(),
      });
    }
  }, [educationInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderEducationDetails = () => {
    return (
      <>
        <Text style={{ textAlign: "center", textDecoration: "underline" }}>
          Education Verification
        </Text>
        {educationInfo?.length > 0 && (
          <>
            {educationInfo.map((educationEvidence, index) => (
              <React.Fragment key={index}>
                {/* Render table on one page */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Education Details:
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      Status:
                    </Text>
                    <View
                      style={{
                        backgroundColor: getStatusColor(
                          educationEvidence?.education?.status
                        ),
                        paddingVertical: 2,
                        paddingHorizontal: 10,
                        borderRadius: 4,
                      }}
                    >
                      <Text
                        style={{
                          color: "black",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {educationEvidence?.education?.status}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={EducationCheckStyles.table}>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Details
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      As Provided
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      As Verified
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Qualification
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.education?.qualification}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.qualificationRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Name of the College / Institute
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.education?.schoolName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.verificationDetails?.schoolNameRemark}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Affiliated to University/ Board
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.education?.unversityName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.unversityNameRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Roll No.
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.education?.rollNumber}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.verificationDetails?.rollNumberRemark}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Year of passing
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.education?.yearOfPassing}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.yearOfPassingRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Mode of verification
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.education?.modeOfVerification}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Verified By
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.education?.verifiedBy}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Contact Details
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Telphone : {educationEvidence?.education?.contactDetails}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Additional remarks if any
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {educationEvidence?.education?.additionalRemarks}
                    </Text>
                  </View>
                </View>
                {/* <View break /> */}
                {educationEvidence?.education?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={EducationCheckStyles.centeredImageContainer}
                      wrap={false} // Prevent image splitting between pages
                      break // Page break to place image on a new page
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          margin: "17px 0 0 0",
                          textDecoration: "underline",
                          textAlign: "center",
                          display: "block",
                          marginTop: "50px",
                          marginBottom: "20px",
                        }}
                      >
                        Copy of written verification received from{" "}
                        {educationEvidence?.education?.schoolName}
                      </Text>
                      <AsyncImage
                        fileUrl={url}
                        alt={`Education evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
