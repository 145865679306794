import React, { useState, useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { getBase64 } from "../../Helper/Base64File";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { SplitButton } from "primereact/splitbutton";
import { InputTextarea } from "primereact/inputtextarea";

const IdentityDetails = ({
  userVerificationDetail,
  taskDetail,
  identityCheckInfo,
  getIndetityInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getDiscreteCallInfo(userVerificationDetail);
  }, [userVerificationDetail]);

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.post(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  useEffect(() => {
    setData(identityCheckInfo);
  }, [identityCheckInfo]);

  const onInputChange = (e, index, type) => {
    const { name, value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          identity: {
            ...newData[index].identity,
            [name]: value,
          },
          verificationDetails: {
            ...newData[index].verificationDetails,
            [e.target.name]: e.target.value,
          },
        };
        return newData;
      });
    }
  };

  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });
      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const updateUANDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      identity: {
        ...data[index]?.identity,
        reportUrl: [
          ...new Set([...data[index]?.identity?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.identity?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.identity?.uploadProofUrl,
            ...uploadProof,
          ]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Identity form successfully submitted");
      if (flag === "false") {
        getIndetityInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const handleEdit = () => {
    setEditable(!editable);
  };

  // const updateCheckStatus = async (status, e, id, index, type) => {
  //   e.preventDefault();
  //   const body = {
  //     status: status,
  //     type: type,
  //   };
  //   try {
  //     await ApiService.post(`identityCheck/identity/status/${id}`, body);

  //     toast.success("Check Task updated!");
  //     // updateTaskItem(id, status, type);
  //     getIndetityInfo(userVerificationDetail);
  //   } catch (error) {
  //     console.error("Error In Task Update", error);
  //     toast.error("Error In Task Update");
  //   }
  // };

  const updateCheckStatus = async (status, id) => {
    const body = { status: status };
    try {
      await ApiService.post(`identityCheck/identity/status/${id}`, body);
      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      // getDiscreteCallInfo(userVerificationDetail);
      getIndetityInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  const createActivity = async (action, caseId, checkId) => {
    let body = {
      userVerificationRequestId: caseId,
      checkId: checkId,
      FEName: `${localStorageData?.firstName} ${localStorageData?.lastName}`,
      checkName: "Identity",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"}`}
          >
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <div className="flex flex-row justify-between mb-[10px]">
                  <div className="flex justify-between">
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                      <SplitButton
                        label="Update Status"
                        icon="pi pi-check"
                        model={[
                          {
                            label: "Major Discrepancy",
                            command: () => {
                              createActivity(
                                "Pending -> Major Discrepancy",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Major Discrepancy",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Minor Discrepancy",
                            command: () => {
                              createActivity(
                                "Pending -> Minor Discrepancy",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Minor Discrepancy",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Verified Clear",
                            command: () => {
                              createActivity(
                                "Pending -> Verified Clear",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Verified Clear",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Insufficiency",
                            command: () => {
                              createActivity(
                                "Pending -> Insufficiency",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Insufficiency",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Unable to Verify",
                            command: () => {
                              createActivity(
                                "Pending -> Unable to Verify",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Unable to Verify",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Stop Check",
                            command: () => {
                              createActivity(
                                "Pending -> Stop Check",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Stop Check",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                        ]}
                      />
                    )}
                    <div className="ml-[20px]">
                      {getSeverity(item?.court?.status)}
                    </div>
                  </div>
                </div>
              )}

              {process.env.REACT_APP_IS_GVS_REPORT_ENABLED === "true" && (
                <form>
                  {item?.identity?.type === "Aadhaar Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Aadhaar Card Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Aadhaar Card
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "PAN Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Card Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Pan Card
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Driving License" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Driving Licence Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Licence
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Issue
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfIssue"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfIssue || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Expiry
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfExpiry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfExpiry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Country
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="country"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.country || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Passport" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Do you have passport?
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="isDocAvailable"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.isDocAvailable || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Passport Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Passport
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Expiry
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfExpiry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfExpiry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Issue Country
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="issueCountry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.issueCountry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Voter ID" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            VoterId Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On VoterId
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row my-2"></div>
                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="clientType">
                      Additional Remarks
                    </label>
                    <InputTextarea
                      className="form-control"
                      type="text"
                      name="additionalRemarks"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.identity?.additionalRemarks || ""}
                      disabled={flag === "true"}
                      rows={5}
                      cols={30}
                    />
                  </div>

                  {/* ----------------------------------------------------------------------------- */}
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.identity?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={50000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                            disabled={flag === "true"}
                          />
                        ) : (
                          ""
                        )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.identity?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  // onClick={(e) =>
                                  //   removeImg(
                                  //     e,
                                  //     item._id,
                                  //     item,
                                  //     reportIndex,
                                  //     "file"
                                  //   )
                                  // }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="remark">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="remark"
                      onChange={(e) => {
                        // setRemarkForActivity(e.target.value);
                        onInputChange(e, dataIndex, "alphanumeric");
                      }}
                      value={item?.identity?.remark || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof{" "}
                      <span className="opacity-70">
                        (Please Choose PDF less than 10MB)
                      </span>
                    </label>
                    {item?.identity?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        customUpload={true}
                        maxFileSize={10000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProof")
                        }
                        itemTemplate={customItemTemplate}
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.identity?.uploadProofUrl || []).map(
                        (fileUrl, proofIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={proofIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <div
                                role="presentation"
                                // onClick={(e) =>
                                //   removeImg(
                                //     e,
                                //     item._id,
                                //     item,
                                //     proofIndex,
                                //     "proof"
                                //   )
                                // }
                                className="cursor-pointer"
                              >
                                <i
                                  className="pi pi-times"
                                  style={{ color: "red" }}
                                ></i>
                              </div>
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    proofIndex,
                                    "proof"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />
                              <div className="text-center mt-2">
                                {isImage &&
                                  proofImage[dataIndex]?.[proofIndex] && (
                                  <a
                                    href={proofImage[dataIndex][proofIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${proofIndex}`}
                                  >
                                      View Image
                                  </a>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.identity?.status === "Pending" ? (
                        item?.identity?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {item?.identity?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateUANDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.identity?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.identity?.status)}</div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default IdentityDetails;
