import SignupPage from "./views/SignupPage.jsx";
import LoginPage from "./views/LoginPage.jsx";
import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { WebForm } from "./components/StaffMember/WebForm.jsx";
// import NotFoundPage from "./views/NotFoundPage";
import { VerificationReport } from "./components/CompanyPages/VerificationReport.jsx";
import { InterimReport } from "./components/CompanyPages/InterimReport.jsx";
import VerificationPage from "./components/BCAPages/UserList.jsx";
import { EvidenceForm } from "./components/UserProfileView/EvidenceForm.jsx";

import { UserLink } from "./components/UserProfileView/UserLink.jsx";
import { Team } from "./components/BCAPages/Team.jsx";
import PamacReport from "./components/Reports/PamacReport/PamacReport.jsx";
import GvsReport from "./components/Reports/GvsReport/GvsReport.jsx";
import SiriusReport from "./components/Reports/SiriusReport/SiriusReport.jsx";

// const  NotFoundPage = lazy(() => import("./views/NotFoundPage"))
const AuthRoute = lazy(() => import("./middleware/AuthRoute.jsx"));
const VerifyOTP = lazy(() => import("./views/VerifyOPTPage.jsx"));
const BCAHomePage = lazy(() => import("./views/BCAHomePage.jsx"));
const CompanyHomePage = lazy(() => import("./views/CompanyHomePage.jsx"));
const ProfilePages = lazy(() => import("./views/ProfilePage.jsx"));
const UserProfilePage = lazy(() => import("./views/UserProfilePage.jsx"));
// const DetailPage = lazy(() => import("./views/DetailPage"));
// const UserVerificationView = lazy(
//   () => import("./views/UserVerificationViewPage.jsx")
// );
const StaffMemberList = lazy(
  () => import("./components/BCAPages/StaffMemberList.jsx")
);
const StaffMemberPage = lazy(() => import("./views/StaffMemberHomePage.jsx"));
const AssignedTaskPage = lazy(() => import("./views/UploadDocumentTab.jsx"));
// const VerificationHistory = lazy(
//   () => import("./views/VerificationHistory.jsx")
// );
const Employee = lazy(() => import("./components/CompanyPages/Employee.jsx"));

// const Detail = lazy(() => import("./components/AuthPages/CCTAdmin/Detail.jsx"));
const Invoice = lazy(() => import("./components/BCAPages/Invoice.jsx"));
const TransactionHistory = lazy(
  () => import("./components/CompanyPages/TransactionHistory.jsx")
);

const CriticalTask = lazy(
  () => import("./components/StaffMember/CriticalTask.jsx")
);

const CompanyList = lazy(() => import("./components/BCAPages/CompanyList.jsx"));

export default function Routers() {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Routes>
        {/* guest routes */}
        {process.env.REACT_APP_ENABLE_USER_SIGNUP === "true" && (
          <Route exact path="/signup" element={<SignupPage />} />
        )}

        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/verify" element={<VerifyOTP />} />
        <Route exact path="/verify/:otp/:email" element={<VerifyOTP />} />
        {/* <Route path="/userLink/:webFormId" element={<UserLink />} /> */}
        <Route path="/webform/:webFormId" element={<WebForm />} />

        {process.env.REACT_APP_PUBLIC_USER_LINK === "true" && (
          <Route path="/userLink/:webFormId" element={<UserLink />} />
        )}

        {/* private route */}
        <Route element={<AuthRoute />}>
          {/* <Route path="/user" element={<UserHomePage />} /> */}
          <Route path="/verification-details" element={<UserProfilePage />} />
          <Route path="/bca" element={<BCAHomePage />} />
          <Route path="/company" element={<CompanyHomePage />} />
          <Route path="/evidence-form" element={<EvidenceForm />} />
          {/* <Route path="/upload/:verificationId" element={<UserLink />} /> */}
          <Route path="/profile" element={<ProfilePages />} />
          {/* this route will use with authentication to required login */}

          {process.env.REACT_APP_PUBLIC_USER_LINK === "false" && (
            <Route path="/userLink" element={<UserLink />} />
          )}

          {/* <Route
            path="/user-verification-view"
            element={<UserVerificationView />}
          /> */}
          <Route path="/view-staff-member-list" element={<StaffMemberList />} />
          <Route path="/staff-member-dashboard" element={<StaffMemberPage />} />
          <Route path="/assigned-task-page" element={<AssignedTaskPage />} />
          <Route path="/employee" element={<Employee />} />
          {/* <Route path="/detail" element={<Detail />} /> */}
          <Route path="/cases" element={<VerificationPage />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/transaction" element={<TransactionHistory />} />

          <Route path="/criticalTask" element={<CriticalTask />} />
          <Route path="/companyList" element={<CompanyList />} />

          <Route path="/team" element={<Team />} />

          {/* <Route
            path="user-verification-history"
            element={<VerificationHistory />}
          /> */}
        </Route>
        <Route path="/report" element={<VerificationReport />} />
        <Route path="/generate-report" element={<InterimReport />} />
        <Route path="/pamac-report" element={<PamacReport />} />
        <Route path="/gvs-report" element={<GvsReport />} />
        <Route path="/sirius-report" element={<SiriusReport />} />

        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
}
