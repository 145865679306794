/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { SplitButton } from "primereact/splitbutton";

const VideoKycDetails = ({
  userVerificationDetail,
  taskDetail,
  videoKycCheckInfo,
  getVideoKycCheckInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [editable, setEditable] = useState(false);
  const [uploadProof, SetUploadProof] = useState([]);
  // const [remarkForActivity, setRemarkForActivity] = useState("");

  let localStorageData = JSON.parse(localStorage.getItem("user"));

  const createActivity = async (action, caseId, checkId) => {
    let body = {
      userVerificationRequestId: caseId,
      checkId: checkId,
      FEName: `${localStorageData?.firstName} ${localStorageData?.lastName}`,
      checkName: "Video KYC",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "videoKycCheck/link";
    } else {
      url = "videoKycCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.post(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        // Create a copy of the original data array
        const newData = [...prevData];
        // Update the specific object at the given index
        newData[index] = {
          ...newData[index],
          videoKyc: {
            ...newData[index].videoKyc,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };

  useEffect(() => {
    setData(videoKycCheckInfo);
    SetFileUplaod(data?.videoKyc?.reportUrl || []);
  }, [videoKycCheckInfo]);

  const updateDiscreteCalls = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "videoKycCheck/link";
    } else {
      url = "videoKycCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      videoKyc: {
        ...data[index]?.videoKyc,
        reportUrl: [...data[index]?.videoKyc?.reportUrl, ...fileUpload],
        remark: data[index]?.videoKyc?.remark,
        uploadProofUrl: [
          ...data[index]?.videoKyc?.uploadProofUrl,
          ...uploadProof,
        ],
      },
    };
    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   videoKycCheckInfo[0]._id
      // );
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Video Kyc details updated successfully");
      if (flag === "false") {
        getVideoKycCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.post(`videoKycCheck/videoKyc/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getVideoKycCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "videoKyc",
  //   };
  //   await ApiService.post(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const handleFileUpload = async (event, category) => {
    const files = event.files;
    const fileData = [];
    for (let file of files) {
      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = () => {
    setEditable(!editable);
  };
  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Video KYC Task Submitted!",
                userVerificationDetail,
                videoKycCheckInfo[0]._id
              );
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, index) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${index === 0 ? "mt-0" : "mt-4"}`}
          >
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex flex-row justify-between mb-[10px]">
                    <div className="flex justify-between">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true" && (
                        <div className="flex flex-row gap-1">
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Approved",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Approved",

                                item?._id,
                                index
                              );
                            }}
                          >
                            <Button
                              label="Approve"
                              severity="success"
                              icon="pi text-white pi-check-circle"
                              size="small"
                            />
                          </div>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Rejected",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Rejected",

                                item?._id,
                                index
                              );
                            }}
                          >
                            <Button
                              label="Reject"
                              icon="pi text-white pi-times-circle"
                              severity="danger"
                              size="small"
                            />
                          </div>
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                        <SplitButton
                          label="Update Status"
                          icon="pi pi-check"
                          // onClick={(e) => {
                          //   // Do nothing, the menu items will handle the status update
                          // }}
                          model={[
                            {
                              label: "Major Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Major Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Major Discrepancy",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Minor Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Minor Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Minor Discrepancy",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Verified Clear",
                              command: () => {
                                createActivity(
                                  "Pending -> Verified Clear",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Verified Clear",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Insufficiency",
                              command: () => {
                                createActivity(
                                  "Pending -> Insufficiency",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Insufficiency",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Unable to Verify",
                              command: () => {
                                createActivity(
                                  "Pending -> Unable to Verify",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Unable to Verify",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Stop Check",
                              command: () => {
                                createActivity(
                                  "Pending -> Stop Check",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Stop Check",

                                  item?._id,
                                  index
                                );
                              },
                            },
                          ]}
                        />
                      )}

                      <>
                        <div>{getSeverity(item?.videoKyc?.status)}</div>
                      </>
                    </div>
                  </div>
                </>
              )}
              <form>
                <div className="row my-2">
                  <div className="col-md-12 border-bottom">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Video
                    </label>
                    {item?.videoKyc?.reportUrl?.length === 0 ||
                    editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          multiple
                          auto
                          accept="video/*"
                          customUpload={true}
                          maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          disabled={
                            flag === "true" ||
                          localStorageData?.role === "Auditor"
                          }
                        />
                      ) : (
                        ""
                      )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.videoKyc?.reportUrl || []).map(
                        (fileUrl, index) => (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            {/* <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image ${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div> */}
                            <a
                              href={fileUrl}
                              target="_blank"
                              rel="noreferrer"
                              download={`file_Image_${index}`}
                              className="text-center"
                            >
                              Play Video
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>

                <div className="col-md-8">
                  <label className="small mb-1" htmlFor="remark">
                    Remark
                  </label>
                  <textarea
                    className="form-control h-[110px]"
                    name="remark"
                    onChange={(e) => {
                      // setRemarkForActivity(e.target.value);
                      onInputChange(e, index, "alphanumeric");
                    }}
                    value={item?.videoKyc?.remark || ""}
                    disabled={flag === "false"}
                  />
                </div>

                {/* <div className="col-md-12 my-2">
                  <label className="small mb-1" htmlFor="clientType">
                    Upload Proof
                  </label>
                  {item?.videoKyc?.uploadProofUrl?.length === 0 && (
                    <FileUpload
                      name="fileUpload[]"
                      multiple
                      accept="image/*"
                      customUpload={true}
                     maxFileSize={50000000}
                      uploadHandler={(e) => handleFileUpload(e, "uploadProof")}
                      disabled={flag === "false"}
                    />
                  )}
                  <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                    {(item?.videoKyc?.uploadProofUrl || []).map(
                      (fileUrl, index) => (
                        <li
                          key={Math.random().toString(36).substr(2, 9)}
                          className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                        >
                          <div className="h-[200px]">
                            <img
                              src={fileUrl}
                              alt={`file_Image ${index}`}
                              className="h-[200px] w-[200px] object-contain"
                            />
                          </div>
                          <a
                            href={fileUrl}
                            download={`file_Image_${index}`}
                            className="text-center"
                          >
                            Download
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div> */}
                <div></div>

                <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                  {flag === "false" && item?.videoKyc?.status === "Pending" ? (
                    item?.videoKyc?.reportUrl?.length > 0 ? (
                      <Button
                        label="Edit"
                        type="button"
                        onClick={(e) => {
                          handleEdit(e);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {item?.videoKyc?.status === "Pending" ? (
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateDiscreteCalls(e, item._id, index);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.videoKyc?.status)}</div>
                    </div>
                  ) : (
                    <div>{getSeverity(item?.videoKyc?.status)}</div>
                  )}
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default VideoKycDetails;
