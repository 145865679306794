import React, { useState } from "react";
import { CoordinatorList } from "./CoordinatorList.jsx";
import { StaffMemberList } from "./StaffMemberList.jsx";
import { VendorList } from "./VendorList.jsx";
import { DataEntry } from "./DataEntry.jsx";
import { Auditor } from "./Auditor.jsx";

// import ApiService from "../../services/APIServices";

export const Team = () => {
  const LocalData = JSON.parse(localStorage.getItem("user"));
  const [ActiveTabInfo, setActiveTabInfo] = useState("Verification-Executive");

  // useEffect(() => {
  //   // const LocalData = JSON.parse(localStorage.getItem("user"));
  //     setActiveTabInfo("Verification-Executive");
  // }, []);

  const handleIsTabClick = (tabNameInfo) => {
    setActiveTabInfo(tabNameInfo);
  };

  const renderTabsInfo = (ActiveTabInfo) => {
    switch (ActiveTabInfo) {
    case "Client-Coordinator":
      return (
        <>
          <CoordinatorList />
        </>
      );
    case "Verification-Executive":
      return (
        <>
          <StaffMemberList />
        </>
      );
    case "Data-Entry":
      return (
        <>
          <DataEntry />
        </>
      );

    case "Quality-Check":
      return (
        <>
          <Auditor />
        </>
      );

    case "Vendor":
      return (
        <>
          <VendorList />
        </>
      );
    }
  };

  return (
    <>
      <div className="container-xl px-auto mt-3">
        <div className="flex lg:flex-row px-[12px] flex-col xl:pt-0 lg:pt-[10%] md:pt-[10%] pt-[15%] lg:overflow-x-auto overflow-style-none md:rounded-lg gap-[20px]">
          {[
            "Verification-Executive",
            "Client-Coordinator",
            "Quality-Check",
            "Data-Entry",
            "Vendor",
          ]
            .filter((tabName) => {
              if (LocalData?.role === "Coordinator") {
                return (
                  // tabName !== "Client-Coordinator" &&
                  tabName !== "Vendor" &&
                  tabName !== "Quality-Check" &&
                  tabName !== "Data-Entry"
                );
              } else if (LocalData?.role === "Vendor") {
                return tabName !== "Vendor" && tabName !== "Quality-Check";
              } else if (LocalData?.role === "BCAStaff") {
                return (
                  tabName !== "Client-Coordinator" &&
                  tabName !== "Quality-Check"
                );
              }
              return true;
            })
            .map((tabName) => (
              <div
                key={Math.random().toString(36).substr(2, 9)}
                role="presentation"
                onClick={() => handleIsTabClick(tabName)}
                className={`${
                  ActiveTabInfo === tabName
                    ? "bg-gradient-to-r from-sky-800 to-teal-400 rounded-[7px] shadow-md text-white text-[18px] font-medium font-['Poppins'] w-auto"
                    : "bg-[#B8B8B8] rounded-[7px] shadow-md text-white text-[18px] font-medium font-['Poppins'] w-auto"
                } py-2 px-4 h-[36px] cursor-pointer flex flex-row`}
              >
                <div className="h-[20px] lg:text-nowrap">
                  {(tabName, ActiveTabInfo === tabName)}
                </div>
                <div className="h-[20px] text-[14px] lg:text-nowrap">
                  {tabName}
                </div>
              </div>
            ))}
        </div>

        <div className="h-auto w-[1px] bg-gray-300"></div>
        <div className="h-auto p-[12px] mt-[5px] ">
          {renderTabsInfo(ActiveTabInfo)}
        </div>
      </div>
    </>
  );
};
