import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useRowSelect, useSortBy } from "react-table";
import ApiService from "../../services/APIServices";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Calendar } from "primereact/calendar";
import { Tooltip } from "antd";
import { utils, writeFile } from "xlsx";
import {
  VerificationStatusParameters,
  cooordinatorList,
  dateFilterStatus,
  getSeverity,
  priorityFilters,
} from "../../utils/constants.js";
import WeactFilterTabs from "./WeactFiltersTab.jsx";
import NoData from "../../Helper/NoDataFound.jsx";
import { ProgressSpinner } from "primereact/progressspinner";

export default function VerificationPage() {
  const navigate = useNavigate();
  const [BCAInfo, setBCAInfo] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [search, setSearch] = useState("");
  const [caseSearch, setCaseSearch] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedDateStatus, setSelectedDateStatus] = useState(null);
  const [filterQuery, setFilterQuery] = useState(null);
  const [rightSideBar, setRightSidebar] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [dates, setDates] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCoordinator, setSelectedCoordinator] = useState(null);
  const [selectedCoordinatorFilter, setSelectedCoordinatorFilter] =
    useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const localData = JSON.parse(localStorage.getItem("user"));

  const getVendorList = () => {
    let BCA = JSON.parse(localStorage.getItem("user"));
    ApiService.get(`BCAStaff/vendor/${BCA._id}`, {
      text: search,
    })
      .then((res) => {
        setVendorList(res?.data?.docs);
        // setHasStaffAccess(res?.data?.hasStaffAccess);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  };

  const onFilter = () => {
    setRightSidebar(true);
  };

  const handlePriorityChange = (value, id) => {
    const selectedValue = value;
    setSelectedPriority(selectedValue); // Update the state with the selected value

    // Call handleSvgClick function with selected data
    handleSvgClick(selectedValue, id);
  };

  const handleSvgClick = (selectedValue, ID) => {
    let body = {
      priority: selectedValue, // Pass the selected value to the API call
    };
    ApiService.post(`userVerificationRequest/${ID}`, body)
      .then(() => {
        setSelectedPriority("");

        // toast.success("Priority Set Successfully");
        // setVerificationStatus("Approved");
        // getUserVerificationReqInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirm1 = (id) => {
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      reject: () => setDialogVisible(false), // Close dialog on reject
      onHide: () => setDialogVisible(false), // Close dialog on close
    });
  };

  const accept = (id) => {
    handleDeleteClick(id);
    // navigate("/bca");
  };

  const handleDeleteClick = async (id) => {
    let body = {
      isDeleted: true,
    };
    ApiService.post(`userVerificationRequest/${id}`, body)
      .then(() => {
        setDialogVisible(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllowedHeaders = () => {
    // Initialize empty array for allowed headers
    let allowedHeaders = [];

    // Check if VERIFYKART columns should be included
    if (process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS === "true") {
      const verifyKartColumns = process.env.REACT_APP_VERIFYKART_BCA_COLUMNS;
      if (verifyKartColumns) {
        allowedHeaders = verifyKartColumns;
      } else if (verifyKartColumns === undefined) {
        allowedHeaders = [
          "City",
          "State",
          "Pincode",
          "Name",
          "ID",
          "Email",
          "Company",
          "Requested On",
          "Status",
          "Report Date",
          "Payment-Status",
          "Invoice",
          "Priority Setting",
        ];
      }
    }

    // Check if WEACT columns should be included
    else if (process.env.REACT_APP_SHOW_WEACT_COLUMNS === "true") {
      const weActColumns = process.env.REACT_APP_WEACT_BCA_COLUMNS;
      if (weActColumns) {
        allowedHeaders = weActColumns;
      }
    } else if (process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true") {
      const pamacColumns = process.env.REACT_APP_PAMAC_BCA_COLUMNS;
      if (pamacColumns) {
        allowedHeaders = pamacColumns;
      }
    }

    // Remove duplicate headers (if any)
    return allowedHeaders;
  };

  const allowedHeaders = getAllowedHeaders();

  const COLUMNS = React.useMemo(() => {
    const getColumnData = () => {
      const columns = [
        {
          Header: "",
          accessor: "checkbox",
        },
        {
          Header: "ID",
          accessor: "case",
          Cell: ({ row }) => {
            return (
              (
                <>
                  <div className="flex cursor-pointer align-items-center gap-2">
                    <span>{row?.original?.case}</span>
                  </div>
                </>
              ) || <span>NA</span>
            );
          },
        },
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ row }) => {
            return (
              (
                <>
                  <div
                    role="presentation"
                    onClick={() => {
                      navigate("/verification-details", {
                        state: {
                          verificationDetails: row?.original,
                        },
                      });
                    }}
                    className="flex cursor-pointer align-items-center gap-2 text-teal-500 hover:text-blue-500"
                  >
                    <span>
                      <Tooltip
                        placement="top"
                        title="Click here to access detail page"
                      >
                        <span>
                          {row?.original?.userInfo?.firstName}{" "}
                          {row?.original?.userInfo?.lastName}
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                </>
              ) || <span>NA</span>
            );
          },
        },
        {
          Header: "Case Number",
          accessor: "caseNumber",
          Cell: ({ row }) => {
            return (
              <div>
                {row?.original?.userInfo?.caseNumber || <span>NA</span>}
              </div>
            );
          },
        },
        {
          Header: "Client Name",
          accessor: "clientName",
          Cell: ({ row }) => {
            return (
              <div
                role="presentation"
                onClick={() => {
                  navigate("/verification-details", {
                    state: {
                      verificationDetails: row?.original,
                    },
                  });
                }}
              >
                {row?.original?.userInfo?.clientName || <span>NA</span>}
              </div>
            );
          },
        },
        {
          Header: "Verification For",
          accessor: "checks",
          Cell: ({ row }) => {
            return <div>{row?.original?.checks[0] || <span>NA</span>}</div>;
          },
        },
        {
          Header: "Address",
          accessor: "address",
          Cell: ({ row }) => {
            return (
              <>
                {row?.original?.userInfo?.address?.length > 10 ? (
                  <div>
                    <Tooltip
                      title={row?.original?.userInfo?.address}
                      placement="bottom"
                    >
                      {row?.original?.userInfo?.address.substring(0, 10)}....
                    </Tooltip>
                  </div>
                ) : (
                  <span>{row?.original?.userInfo?.address || "NA"}</span>
                )}
              </>
            );
          },
        },
        {
          Header: "Address 1",
          accessor: "address1",
          Cell: ({ row }) => {
            return (
              <>
                {row?.original?.userInfo?.address1?.length > 10 ? (
                  <div>
                    <Tooltip
                      title={row?.original?.userInfo?.address1}
                      placement="bottom"
                    >
                      {row?.original?.userInfo?.address1.substring(0, 10)}....
                    </Tooltip>
                  </div>
                ) : (
                  <span>{row?.original?.userInfo?.address1 || "NA"}</span>
                )}
              </>
            );
          },
        },
        {
          Header: "Address 2",
          accessor: "address2",
          Cell: ({ row }) => {
            return (
              <>
                {row?.original?.userInfo?.address2?.length > 10 ? (
                  <div>
                    <Tooltip
                      title={row?.original?.userInfo?.address2}
                      placement="bottom"
                    >
                      {row?.original?.userInfo?.address2.substring(0, 10)}....
                    </Tooltip>
                  </div>
                ) : (
                  <span>{row?.original?.userInfo?.address2 || "NA"}</span>
                )}
              </>
            );
          },
        },

        {
          Header: "Email",
          accessor: "userInfo.email",
          Cell: ({ row }) => {
            return (
              (
                <Tooltip placement="top" title={row?.original?.userInfo?.email}>
                  <span>
                    {row?.original?.userInfo?.email.substring(0, 5)}...
                  </span>
                </Tooltip>
              ) || <span>NA</span>
            );
          },
        },
        {
          Header: "Company",
          accessor: "companyInfo.name",
          Cell: ({ row }) => {
            return (
              (
                <Tooltip
                  placement="top"
                  title={row?.original?.companyInfo?.name}
                >
                  <span>
                    {row?.original?.companyInfo?.name.substring(0, 5)}...
                  </span>
                </Tooltip>
              ) || <span>NA</span>
            );
          },
        },
        {
          Header: "Requested On",
          accessor: "requestedDate",
          Cell: ({ row }) => {
            return (
              new Date(row?.original?.requestedDate).toLocaleDateString(
                "en-GB"
              ) || <span>NA</span>
            );
          },
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ row }) => {
            return (
              (
                <div role="presentation" onClick={() => {}}>
                  {getSeverity(row?.original?.status)}
                </div>
              ) || <span>NA</span>
            );
          },
        },

        // {
        //   Header: "Payment-Status",
        //   accessor: "TransactionStatus",
        //   Cell: ({ row }) => {
        //     // Fetch payment status when row data changes

        //     return (
        //       <div>{row?.original?.TransactionStatus || <span>NA</span>}</div>
        //     );
        //   },
        // },
        // {
        //   Header: "Invoice",
        //   accessor: "RazorpayInvoiceUrl",
        //   Cell: ({ row }) => {
        //     return (
        //       (
        //         <a
        //           href={row?.original?.RazorpayInvoiceUrl}
        //           target="_blank"
        //           rel="noreferrer"
        //         >
        //           <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             viewBox="0 0 100 100"
        //             id="invoice"
        //             height="50px"
        //             width="50px"
        //           >
        //             <path d="M17 75h46c1.654 0 3-1.346 3-3V20a1 1 0 0 0-.294-.708L51.708 5.294A.998.998 0 0 0 51 5H17c-1.654 0-3 1.346-3 3v64c0 1.654 1.346 3 3 3zM52 8.414 62.586 19H53c-.552 0-1-.449-1-1V8.414zM16 8c0-.551.448-1 1-1h33v11c0 1.654 1.346 3 3 3h11v51c0 .551-.448 1-1 1H17c-.552 0-1-.449-1-1V8z"></path>
        //             <path d="M27 26a1 1 0 1 0-2 0 5.008 5.008 0 0 0 4 4.899V32a1 1 0 1 0 2 0v-1.101c2.28-.465 4-2.485 4-4.899s-1.72-4.434-4-4.899v-5.917c1.161.414 2 1.514 2 2.816a1 1 0 1 0 2 0 5.008 5.008 0 0 0-4-4.899V12a1 1 0 1 0-2 0v1.101c-2.28.465-4 2.485-4 4.899s1.72 4.434 4 4.899v5.917A2.995 2.995 0 0 1 27 26zm6 0a2.995 2.995 0 0 1-2 2.816v-5.632c1.161.414 2 1.514 2 2.816zm-6-8c0-1.302.839-2.402 2-2.816v5.632A2.995 2.995 0 0 1 27 18zm12 10h21a1 1 0 1 0 0-2H39a1 1 0 1 0 0 2zm21 7H35a1 1 0 1 0 0 2h25a1 1 0 1 0 0-2zM20 46h40a1 1 0 1 0 0-2H20a1 1 0 1 0 0 2zm0 9h40a1 1 0 1 0 0-2H20a1 1 0 1 0 0 2zm0 9h40a1 1 0 1 0 0-2H20a1 1 0 1 0 0 2z"></path>
        //           </svg>
        //         </a>
        //       ) || <span>NA</span>
        //     );
        //   },
        // },
        // {
        //   Header: "Priority Setting",
        //   accessor: "Priority Setting",
        //   Cell: ({ row }) => {
        //     return (
        //       <div>
        //         {/* SVG wrapped inside a clickable element */}
        //         <div
        //         // onClick={() => {
        //         //   handleSvgClick();
        //         // }}
        //         >
        //           <Dropdown
        //             options={priorityFilters}
        //             optionLabel="name"
        //             placeholder="Set Priority"
        //             className="w-14rem"
        //             value={row.original.priority} // Set the value of the dropdown to the selectedPriority state
        //             onChange={(e) => {
        //               handlePriorityChange(e.target.value, row?.original?._id);
        //             }}
        //           />
        //         </div>
        //       </div>
        //     );
        //   },
        // },
        // {
        //   Header: "Delete Request",
        //   accessor: "isDeleted",
        //   Cell: ({ row }) => {
        //     const id = row?.original?._id;
        //     return (
        //       <div
        //         className="cursor-pointer"
        //         role="presentation"
        //         onClick={() => confirm1(id)}
        //       >
        //         <DeleteIcon style={{ color: "red" }} />
        //         {/* <ConfirmDialog /> */}
        //       </div>
        //     );
        //   },
        // },
        {
          Header: "Coordinator Name",
          accessor: "userInfo.coordinatorName",
          Cell: ({ row }) => {
            return (
              <div>{row?.original?.coordinatorName || <span>NA</span>}</div>
            );
          },
        },
      ];

      if (localData?.role !== "DataEntry") {
        columns.push({
          Header: "Priority Setting",
          accessor: "Priority Setting",
          Cell: ({ row }) => {
            return (
              <div>
                {/* SVG wrapped inside a clickable element */}
                <div
                // onClick={() => {
                //   handleSvgClick();
                // }}
                >
                  <Dropdown
                    options={priorityFilters}
                    optionLabel="name"
                    placeholder="Set Priority"
                    className="w-14rem"
                    value={row.original.priority} // Set the value of the dropdown to the selectedPriority state
                    onChange={(e) => {
                      handlePriorityChange(e.target.value, row?.original?._id);
                    }}
                  />
                </div>
              </div>
            );
          },
        });
        columns.push({
          Header: "Report Date",
          accessor: "finalReportDate",
          Cell: ({ row }) => {
            const reportDate = row?.original?.finalReportDate;
            return reportDate ? (
              new Date(reportDate).toLocaleDateString("en-GB")
            ) : (
              <span>NA</span>
            );
          },
        });
        columns.push({
          Header: "Payment",
          accessor: "TransactionStatus",
          Cell: ({ row }) => {
            // Fetch payment status when row data changes

            return (
              <div>{row?.original?.TransactionStatus || <span>NA</span>}</div>
            );
          },
        });

        columns.push({
          Header: "Invoice",
          accessor: "RazorpayInvoiceUrl",
          Cell: ({ row }) => {
            return (
              (
                <a
                  href={row?.original?.RazorpayInvoiceUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    id="invoice"
                    height="50px"
                    width="50px"
                  >
                    <path d="M17 75h46c1.654 0 3-1.346 3-3V20a1 1 0 0 0-.294-.708L51.708 5.294A.998.998 0 0 0 51 5H17c-1.654 0-3 1.346-3 3v64c0 1.654 1.346 3 3 3zM52 8.414 62.586 19H53c-.552 0-1-.449-1-1V8.414zM16 8c0-.551.448-1 1-1h33v11c0 1.654 1.346 3 3 3h11v51c0 .551-.448 1-1 1H17c-.552 0-1-.449-1-1V8z"></path>
                    <path d="M27 26a1 1 0 1 0-2 0 5.008 5.008 0 0 0 4 4.899V32a1 1 0 1 0 2 0v-1.101c2.28-.465 4-2.485 4-4.899s-1.72-4.434-4-4.899v-5.917c1.161.414 2 1.514 2 2.816a1 1 0 1 0 2 0 5.008 5.008 0 0 0-4-4.899V12a1 1 0 1 0-2 0v1.101c-2.28.465-4 2.485-4 4.899s1.72 4.434 4 4.899v5.917A2.995 2.995 0 0 1 27 26zm6 0a2.995 2.995 0 0 1-2 2.816v-5.632c1.161.414 2 1.514 2 2.816zm-6-8c0-1.302.839-2.402 2-2.816v5.632A2.995 2.995 0 0 1 27 18zm12 10h21a1 1 0 1 0 0-2H39a1 1 0 1 0 0 2zm21 7H35a1 1 0 1 0 0 2h25a1 1 0 1 0 0-2zM20 46h40a1 1 0 1 0 0-2H20a1 1 0 1 0 0 2zm0 9h40a1 1 0 1 0 0-2H20a1 1 0 1 0 0 2zm0 9h40a1 1 0 1 0 0-2H20a1 1 0 1 0 0 2z"></path>
                  </svg>
                </a>
              ) || <span>NA</span>
            );
          },
        });
      }

      columns.push({
        Header: "City",
        accessor: "city",
        Cell: ({ row }) => {
          return (
            <>
              {row?.original?.userInfo?.city?.length > 20 ? (
                <div>
                  <Tooltip
                    title={row?.original?.userInfo?.city}
                    placement="bottom"
                  >
                    {row?.original?.userInfo?.city.substring(0, 20)}....
                  </Tooltip>
                </div>
              ) : (
                <span>{row?.original?.userInfo?.city || "NA"}</span>
              )}
            </>
          );
        },
      });
      columns.push({
        Header: "State",
        accessor: "state",
        Cell: ({ row }) => {
          return <div>{row?.original?.userInfo?.state || <span>NA</span>}</div>;
        },
      });
      columns.push({
        Header: "Pincode",
        accessor: "pincode",
        Cell: ({ row }) => {
          return (
            <div>{row?.original?.userInfo?.pincode || <span>NA</span>}</div>
          );
        },
      });

      if (localData?.role === "BCA") {
        columns.push({
          Header: "Delete",
          accessor: "isDeleted",
          Cell: ({ row }) => {
            const id = row?.original?._id;
            return (
              <div
                className="cursor-pointer"
                role="presentation"
                onClick={() => confirm1(id)}
              >
                <DeleteIcon style={{ color: "red" }} />
                {/* <ConfirmDialog /> */}
              </div>
            );
          },
        });
      }
      return columns.filter((column) => allowedHeaders.includes(column.Header));
    };

    return getColumnData();
  }, []);

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => userInfo, [userInfo]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          // Render a checkbox in the header
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <input
              type="checkbox"
              {...getToggleAllRowsSelectedProps()}
              onChange={() => toggleAllRowsSelected()}
            />
          ),
          // Render a checkbox in each row
          Cell: ({ row }) => (
            <input
              type="checkbox"
              onChange={() => row.toggleRowSelected()}
              checked={row.isSelected}
            />
          ),
        },
        ...columns,
      ]);
    }
  );

  const assignCoordinator = () => {
    const selectedIds = Object.keys(selectedRowIds)
      .filter((id) => selectedRowIds[id])
      .map((id) => rows.find((row) => row.id === id)?.original?._id);

    let body = {
      ids: selectedIds, // Pass the selected array to the API call
      coordinatorName: selectedCoordinator,
    };
    ApiService.post("userVerificationRequest/update/multipleRequest", body)
      .then(() => {
        toast.success("Coordinator Assigned Successfully");
        // setVerificationStatus("Approved");
        // getUserVerificationReqInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendLinkToVendor = (email, name) => {
    let linkUrl = `${process.env.REACT_APP_FRONTEND_BASE_URL}`;

    // setIsLoadingSend(true);
    ApiService.post("sendLinkToVendor", {
      email: email,
      name: name,
      url: linkUrl,
    })
      .then(() => {
        // setIsLoadingSend(false);
        // setShowInviteModal(false);
        toast.success("Email Sent Successfully");
        // updateCaseIsHRApproved(id, status);
        // fetchData();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const assignVendor = () => {
    const selectedIds = Object.keys(selectedRowIds)
      .filter((id) => selectedRowIds[id])
      .map((id) => rows.find((row) => row.id === id)?.original?._id);

    let body = {
      ids: selectedIds, // Pass the selected array to the API call
      vendorId: selectedVendor?._id,
    };
    ApiService.post("userVerificationRequest/update/vendorId", body)
      .then(() => {
        toast.success("Vendor Assigned Successfully");
        sendLinkToVendor(selectedVendor?.email, selectedVendor?.vendorName);
        // setVerificationStatus("Approved");
        // getUserVerificationReqInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getUserInfo();
    getAllCompany();
  }, []);

  useEffect(() => {
    getVendorList();
  }, [userInfo]);

  const getUserInfo = () => {
    let BCA = JSON.parse(localStorage.getItem("user"));
    setBCAInfo(BCA);
  };

  const handleChange = (value) => {
    setPage(value);
  };

  let queryParams = `/?limit=5&page=${page}`;

  if (localData?.role === "BCA") {
    queryParams += `&BCAId=${localData?._id}`;
  } else {
    queryParams += `&BCAId=${localData?.BCAId}`;
  }

  const getAllCompany = async () => {
    try {
      const res = await ApiService.get(`company/BCAId${queryParams}`);
      setCompanyList(res?.data?.docs);
    } catch (err) {
      console.log(err);
    }
  };

  function convertUserInfoToExcel(userInfoArray, filename) {
    // Define column headers
    const headers = [
      "First Name",
      "Last Name",
      "Email",
      "Phone Number",
      "Company",
      "Role",
      "Status",
      "Final Report Date",
      "Request Date",
    ];

    // Create an array to hold all rows of data
    const dataRows = [];

    // Iterate over each userInfo object
    userInfoArray.forEach((userInfoObj) => {
      // Extracting relevant fields from the userInfo object
      const { userInfo, companyInfo, status, requestedDate, finalReportDate } =
        userInfoObj;
      const { firstName, lastName, email, phoneNumber, role } = userInfo;
      const { name: companyName } = companyInfo;

      // Create a row for this userInfo object
      const rowData = [
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        role,
        status,
        finalReportDate,
        requestedDate,
      ];

      // Add this row to the dataRows array
      dataRows.push(rowData);
    });

    // Insert headers as the first row of data
    dataRows.unshift(headers);

    // Create a new worksheet
    const ws = utils.aoa_to_sheet(dataRows);

    // Create a new workbook
    const wb = utils.book_new();

    // Add the worksheet to the workbook
    utils.book_append_sheet(wb, ws, "UserInfo");

    // Write the workbook to an Excel file
    writeFile(wb, `${filename}.xlsx`);
  }

  useEffect(() => {
    let BCA = JSON.parse(localStorage.getItem("user"));

    const companyIds = BCA?.clientId || [];
    console.log("companyIds>>", companyIds);

    // Create the base URL
    let apiUrl = "";

    // Determine the endpoint based on the role
    if (BCA?.role === "BCA") {
      apiUrl = `userVerificationRequest/ById/${BCA?._id}`;
    } else if (BCA?.role === "Coordinator") {
      const idsParam = companyIds.join(",");
      console.log("idsParam>>", idsParam);

      apiUrl = `userVerificationRequest/companyIds/${idsParam}`;
    } else if (BCA?.role === "Vendor") {
      apiUrl = `userVerificationRequest/vendorId/${BCA?._id}`;
    } else {
      apiUrl = `userVerificationRequest/ById/${BCA?.BCAId}`;
    }

    // Create the base query parameters
    let queryParams = `?limit=300&page=${page}`;

    // Conditionally add populate field
    if (selectedDateStatus) {
      queryParams += `&populate=${selectedDateStatus}`;
    }

    // Conditionally add fromDate and toDate fields
    if (dates) {
      const formattedDates = dates?.map((date) => {
        const year = date?.getFullYear();
        const month = (date?.getMonth() + 1).toString().padStart(2, "0");
        const day = date?.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      });
      queryParams += `&fromDate=${formattedDates[0]}&toDate=${formattedDates[1]}&sort=case&order=1`;
    }

    // Add filter parameters directly to the queryParams string

    if (search) {
      queryParams += `&text=${search}`;
    }

    if (caseSearch) {
      queryParams += `&case=${caseSearch}`;
    }

    if (selectedStatus) {
      queryParams += `&status=${selectedStatus}`;
    }

    if (selectedCompany) {
      queryParams += `&name=${selectedCompany}`;
    }

    if (filterQuery) {
      queryParams += `&checks=${filterQuery}`;
    }

    if (selectedCoordinatorFilter) {
      queryParams += `&coordinatorName=${selectedCoordinatorFilter}`;
    }

    // Make the API request with the dynamically created URL
    ApiService.get(`${apiUrl}${queryParams}`)
      .then((res) => {
        if (BCA?.role === "BCA") {
          setSpinner(false);
        } else {
          setSpinner(false);
        }
        setUserInfo(res?.data?.docs);
        setTotalPages(res?.data?.totalPages);
      })
      .catch(() => {});
  }, [
    page,
    search,
    selectedDateStatus,
    dates,
    caseSearch,
    selectedPriority,
    selectedStatus,
    selectedCompany,
    filterQuery,
    selectedCoordinatorFilter,
    refresh,
  ]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);

    if (tabName === "Discrete Call") {
      setFilterQuery("isDISCRETE");
    }
    if (tabName === "KEI Process") {
      setFilterQuery("isKEI");
    }
    if (tabName === "VOLO Healthcare") {
      setFilterQuery("isVOLO");
    }
    if (tabName === "EDD") {
      setFilterQuery("isEDD");
    }

    if (tabName === "REEDD (Appointment)") {
      setFilterQuery("isREEDDAPPOINTMENT");
    }
    if (tabName === "REEDD (Surprise)") {
      setFilterQuery("isREEDDSURPRISE");
    }
  };
  return (
    <>
      <WeactFilterTabs
        activeTab={activeTab}
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
        handleTabClick={handleTabClick}
      />

      <div className="mx-auto bg-white mt-4">
        <div className="flex flex-col">
          <div className=" shadow p-[30px] overflow-auto overflow-x-auto">
            <div className="inline-block min-w-full  align-middle ">
              <div className="justify-between flex flex-row">
                <div className=" text-black text-[22px] font-semibold text-nowrap ">
                  Verification Cases
                </div>
                <div className="flex flex-row gap-[5px] justify-end h-[50%] max-sm:mt-3">
                  {process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED ===
                  "true" ? (
                      <div className="justify-end flex flex-row gap-2">
                        <Dropdown
                          value={selectedCoordinator}
                          onChange={(e) => {
                            setSelectedCoordinator(e.target.value);
                          }}
                          options={cooordinatorList}
                          optionLabel="name"
                          editable
                          placeholder="Select your name"
                          filter
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                  {BCAInfo?.role === "BCA" &&
                  process.env.REACT_APP_VENDOR_MANAGEMENT_FEATURE_ENABLE ===
                    "true" ? (
                      <>
                        <div className="justify-end flex flex-row gap-2">
                          <Dropdown
                            value={selectedVendor}
                            onChange={(e) => {
                              setSelectedVendor(e.target.value);
                            }}
                            options={vendorList}
                            optionLabel="vendorName"
                            editable
                            placeholder="Select Vendor"
                            filter
                          />
                          <button
                            type="submit"
                            className="w-[110px] h-[47px] text-[12px]  text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow font-semibold"
                            onClick={() => {
                              assignVendor();
                            }}
                          >
                          Assign
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  {process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED ===
                  "true" ? (
                      <div className="justify-end flex flex-row gap-2">
                        <button
                          type="submit"
                          className="w-[110px] h-[47px] text-[12px]  text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow font-semibold"
                          onClick={() => {
                            assignCoordinator();
                          }}
                        >
                        Pick
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  <div className="flex flex-row justify-center">
                    <Button
                      label="Export XLSX"
                      className="!bg-[#0691A2] w-[110px] h-[47px] !text-[12px]"
                      onClick={() =>
                        convertUserInfoToExcel(
                          userInfo,
                          "verificationDataByBCA"
                        )
                      }
                    />
                  </div>
                  <button
                    className="w-[50px]"
                    style={{
                      width: "49px",
                      border: "1px solid #ced4da",
                      borderRadius: "4px",
                    }}
                    onClick={onFilter}
                  >
                    <i className="pi pi-filter mt-1" />
                  </button>
                </div>
                <Sidebar
                  visible={rightSideBar}
                  position="right"
                  onHide={() => setRightSidebar(false)}
                >
                  <div className="flex flex-col gap-1">
                    <label htmlFor="any">Filter by Id</label>
                    <span className="p-input-icon-left w-[17rem]">
                      <i className="pi pi-search" />
                      <InputText
                        value={caseSearch}
                        onChange={(e) => {
                          setCaseSearch(e.target.value);
                        }}
                        placeholder="Filter by Id"
                      />
                    </span>
                    <label htmlFor="any">Filter By Company</label>
                    <Dropdown
                      value={selectedCompany}
                      onChange={(e) => {
                        if (e.target.value?.name == undefined) {
                          setSelectedCompany(e.target.value);
                        } else {
                          setSelectedCompany(e.target.value.name);
                        }
                      }}
                      className="w-[17rem]"
                      options={companyList}
                      optionLabel="name"
                      editable
                      placeholder="Filter By Company"
                      filter
                    />
                    <label htmlFor="any">Filter by User</label>
                    <span className="p-input-icon-left w-[17rem]">
                      <i className="pi pi-search" />
                      <InputText
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        placeholder="Filter by User"
                      />
                    </span>
                    <label htmlFor="any">Select Status</label>
                    <Dropdown
                      onChange={(e) =>
                        setSelectedStatus(
                          e.target.value === "" ? "" : e.target.value
                        )
                      }
                      value={selectedStatus}
                      options={VerificationStatusParameters}
                      optionLabel="name"
                      placeholder="Select a Status"
                      className="w-[17rem]"
                    />
                    <div>
                      <label htmlFor="additionalInfo">Select Date Type</label>
                      <br />
                      <Dropdown
                        value={selectedDateStatus}
                        onChange={(e) => setSelectedDateStatus(e.value)}
                        options={dateFilterStatus}
                        optionLabel="name"
                        placeholder="Select a Type"
                        className="w-[17rem]"
                      />
                    </div>
                    <div className="flex justify-between">
                      {process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED ===
                      "true" ? (
                          <div>
                            <label htmlFor="additionalInfo">
                            Select Coordinator
                            </label>
                            <Dropdown
                              value={selectedCoordinatorFilter}
                              onChange={(e) => {
                                setSelectedCoordinatorFilter(e.target.value);
                              }}
                              options={cooordinatorList}
                              optionLabel="name"
                              editable
                              placeholder="Select your name"
                              filter
                              className="w-[17rem]"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                    </div>
                    <label htmlFor="additionalInfo">Date</label>
                    <Calendar
                      value={dates}
                      onChange={(e) => setDates(e.value)}
                      selectionMode="range"
                      readOnlyInput
                      className="w-[17rem]"
                    />
                  </div>
                  <div className="mt-[10px] flex gap-[20px]">
                    <button
                      type="submit"
                      className="w-[110px]  h-[38px] text-[12px]  text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow font-semibold"
                      onClick={() => {
                        setDates([
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                          ),
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() + 1,
                            0
                          ),
                        ]);
                        setSelectedDateStatus("");
                        setSelectedCoordinatorFilter(null);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="w-[110px]  h-[38px] text-[12px]  text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow font-semibold"
                    >
                      Submit
                    </button>
                  </div>
                </Sidebar>
              </div>

              <div className="w-full mt-[20px]  flex md:flex overflow-x-auto overflow-style-none md:rounded-lg">
                <table
                  className="mt-2 min-w-full divide-y divide-gray-200 dark:divide-gray-700"
                  {...getTableProps()}
                >
                  <thead className="bg-white">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="px-6 py-4"
                        key={Math.random().toString(36).substr(2, 9)}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            className="py-3.5 px-2 text-gray-400 text-sm font-medium rtl:text-right text-nowrap"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ""
                                  : ""
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                    {...getTableBodyProps()}
                  >
                    {spinner ? (
                      <tr>
                        <td colSpan={columns.length}>
                          <div className="text-center mt-1">
                            <ProgressSpinner
                              style={{ width: "50px", height: "50px" }}
                              strokeWidth="8"
                              animationDuration=".7s"
                            />
                          </div>
                        </td>
                      </tr>
                    ) : data.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length}>
                          <NoData info="Ooops, we could not find any case" />
                        </td>
                      </tr>
                    ) : (
                      rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  className="px-2 py-4 text-zinc-800 text-sm font-medium whitespace-nowrap"
                                  key={Math.random().toString(36).substr(2, 9)}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <ConfirmDialog visible={dialogVisible} />
              </div>
              <div className="flex justify-center mt-4 mb-4">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    onChange={handleChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    page={page}
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
