import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { SplitButton } from "primereact/splitbutton";
import { InputTextarea } from "primereact/inputtextarea";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmDialog } from "primereact/confirmdialog";

const DrugDetails = ({
  userVerificationDetail,
  taskDetail,
  drugCheckInfo,
  getDrugCheckInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [list, setList] = useState([]);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);
  // const [remarkForActivity, setRemarkForActivity] = useState("");
  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));
  const confirm1 = (id) => {
    console.log("Delete clicked");
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `drugCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getDrugCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const createActivity = async (action, caseId, checkId) => {
    let body = {
      userVerificationRequestId: caseId,
      checkId: checkId,
      FEName: `${localStorageData?.firstName} ${localStorageData?.lastName}`,
      checkName: "Drug",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  useEffect(() => {
    ApiService.get("userDocument/drug").then((res) => {
      setList(res?.data);
    });
  }, []);
  const getCertificateInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "drugCheck/link";
    } else {
      url = "drugCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {
        // res?.data?.docs[0]; // Assuming there's only one item in the array
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.post(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    // Filter the array and exclude the selected URL by index
    const updatedUrls = item?.drug?.reportUrl.filter(
      (_, index) => index !== reportIndex
    );

    // Update the state with the new array
    // SetFileUplaod(updatedUrls);

    // Call the updateEducationDetails function with the new `updatedUrls` array
    if (type === "file") {
      updateDrugFileArray(e, id, item, updatedUrls);
    } else {
      updateDrugProofArray(e, id, item, updatedUrls);
    }
  };

  const updateDrugFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "drugCheck/link";
    } else {
      url = "drugCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      drug: {
        ...item.drug,
        reportUrl: updatedReportUrls, // Set the updated reportUrl array here
        remark: item.drug.remark,
        uploadProofUrl: [
          ...new Set([...item.drug.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getCertificateInfo(userVerificationDetail);
      toast.success("Drug details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getDrugCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateDrugProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "drugCheck/link";
    } else {
      url = "drugCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      drug: {
        ...item.drug,
        reportUrl: [...new Set([...item.drug.reportUrl, ...fileUpload])], // Set the updated reportUrl array here
        remark: item.drug.remark,
        uploadProofUrl: updatedReportUrls,
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getCertificateInfo(userVerificationDetail);
      toast.success("Drug details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getDrugCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          drug: {
            ...newData[index].drug,
            [e.target.name]: e.target.value,
          },
          verificationDetails: {
            ...newData[index].verificationDetails,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };

  useEffect(() => {
    setData(drugCheckInfo);
    SetFileUplaod(data?.drug?.reportUrl || []);
  }, [drugCheckInfo]);

  const updateCertificateDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "drugCheck/link";
    } else {
      url = "drugCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      drug: {
        ...data[index]?.drug,
        reportUrl: [
          ...new Set([...data[index]?.drug?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.drug?.remark,
        uploadProofUrl: [
          ...new Set([...data[index]?.drug?.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   drugCheckInfo[0]._id
      // );
      getCertificateInfo(userVerificationDetail);
      toast.success("Drug details updated successfully");
      if (flag === "false") {
        getDrugCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };
  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.post(`drugCheck/drug/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getDrugCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "drug",
  //   };
  //   await ApiService.post(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Drug Task Submitted!",
                userVerificationDetail,
                drugCheckInfo[0]._id
              );
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"} relative`}
          >
            <div
              className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
              role="presentation"
              onClick={() => confirm1(item._id)}
            >
              <DeleteIcon
                style={{ color: "red", height: "35px", width: "35px" }}
              />
              {/* <ConfirmDialog /> */}
            </div>
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex flex-row justify-between mb-[10px]">
                    <div className="flex justify-between">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true" && (
                        <div className="flex flex-row gap-1">
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Approved",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Approved",

                                item?._id,
                                dataIndex
                              );
                            }}
                          >
                            <Button
                              label="Approve"
                              severity="success"
                              icon="pi text-white pi-check-circle"
                              size="small"
                            />
                          </div>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Rejected",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Rejected",

                                item?._id,
                                dataIndex
                              );
                            }}
                          >
                            <Button
                              label="Reject"
                              icon="pi text-white pi-times-circle"
                              severity="danger"
                              size="small"
                            />
                          </div>
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                        <SplitButton
                          label="Update Status"
                          icon="pi pi-check"
                          // onClick={(e) => {
                          //   // Do nothing, the menu items will handle the status update
                          // }}
                          model={[
                            {
                              label: "Major Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Major Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Major Discrepancy",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Minor Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Minor Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Minor Discrepancy",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Verified Clear",
                              command: () => {
                                createActivity(
                                  "Pending -> Verified Clear",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Verified Clear",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Insufficiency",
                              command: () => {
                                createActivity(
                                  "Pending -> Insufficiency",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Insufficiency",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Unable to Verify",
                              command: () => {
                                createActivity(
                                  "Pending -> Unable to Verify",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Unable to Verify",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Stop Check",
                              command: () => {
                                createActivity(
                                  "Pending -> Stop Check",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Stop Check",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                          ]}
                        />
                      )}

                      <>
                        <div>{getSeverity(item?.drug?.status)}</div>
                      </>
                    </div>
                  </div>
                </>
              )}
              {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Name
                      </label>
                      <Dropdown
                        value={item?.drug?.name || ""}
                        onChange={(e) => onInputChange(e, dataIndex)}
                        options={list}
                        optionLabel="title"
                        optionValue="title"
                        placeholder="Select Name"
                        filter
                        className="w-full md:w-14rem"
                        inputId="name"
                        name="name"
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Clinic/Lab name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="clinicName"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.drug?.clinicName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Doctor Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="doctorName"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.drug?.doctorName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Clinic/Lab address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="clinicAddress"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.drug?.clinicAddress || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Test Date
                      </label>
                      <InputText
                        className="form-control"
                        type="date"
                        name="testDate"
                        onChange={(e) => onInputChange(e, dataIndex)}
                        value={item?.drug?.testDate || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Report Date
                      </label>
                      <InputText
                        className="form-control"
                        type="date"
                        name="reportDate"
                        onChange={(e) => onInputChange(e, dataIndex)}
                        value={item?.drug?.reportDate || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.drug?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            multiple
                            auto
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={50000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                            disabled={flag === "true"}
                          />
                        ) : (
                          ""
                        )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.drug?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.drug?.remark || ""}
                        disabled={flag === "false"}
                      />
                    </div>

                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.drug?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={50000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={flag === "false"}
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.drug?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      proofIndex,
                                      "proof"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                    <a
                                      href={proofImage[dataIndex][proofIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${dataIndex}_${proofIndex}`}
                                    >
                                        View Image
                                    </a>
                                  )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" && item?.drug?.status === "Pending" ? (
                      item?.drug?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.drug?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateCertificateDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.drug?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.drug?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_SIRIUS_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Name of the candidate
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.drug?.name || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="nameRemark"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.verificationDetails?.nameRemark || ""}
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Test Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="testName"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.drug?.testName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="testNameRemark"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.verificationDetails?.testNameRemark || ""}
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Laboratory
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="laboratory"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.drug?.laboratory || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="laboratoryRemark"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={
                          item?.verificationDetails?.laboratoryRemark || ""
                        }
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Case#
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="case"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.drug?.case || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="caseRemark"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.verificationDetails?.caseRemark || ""}
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Date of test
                      </label>
                      <InputText
                        className="form-control"
                        type="date"
                        name="testDate"
                        onChange={(e) => onInputChange(e, dataIndex)}
                        value={item?.drug?.testDate || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="testDateRemark"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.verificationDetails?.testDateRemark || ""}
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="clientType">
                        Final remarks
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="additionalRemarks"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.drug?.additionalRemarks || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                        rows={5}
                        cols={30}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Remark
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="additionalRemarksRemark"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={
                          item?.verificationDetails?.additionalRemarksRemark ||
                          ""
                        }
                        disabled={
                          flag === "false" ||
                          localStorageData?.role === "Auditor"
                        }
                        rows={5}
                        cols={30}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.drug?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            multiple
                            auto
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={50000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                            disabled={flag === "true"}
                          />
                        ) : (
                          ""
                        )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.drug?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.drug?.remark || ""}
                        disabled={flag === "false"}
                      />
                    </div>

                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.drug?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={50000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={flag === "false"}
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.drug?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      proofIndex,
                                      "proof"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                    <a
                                      href={proofImage[dataIndex][proofIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${dataIndex}_${proofIndex}`}
                                    >
                                        View Image
                                    </a>
                                  )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" && item?.drug?.status === "Pending" ? (
                      item?.drug?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.drug?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateCertificateDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.drug?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.drug?.status)}</div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default DrugDetails;
