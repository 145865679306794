import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const CourtCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "40px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

export const GvsCourtDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [courtInfo, setCourtInfo] = useState([]);

  const getCourtInfo = (id) => {
    if (id) {
      ApiService.get(`courtCheck/${id}`)
        .then((res) => {
          setCourtInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getCourtInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (courtInfo.length > 0) {
      onDataUpdate({
        courtInfo,
        renderCourtDetails: () => renderCourtDetails(),
      });
    }
  }, [courtInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderCourtDetails = () => {
    return (
      <>
        {courtInfo?.length > 0 && (
          <>
            {courtInfo.map((courtEvidence, index) => (
              <React.Fragment key={index}>
                {/* Render table on one page */}
                <View style={CourtCheckStyles.table}>
                  <Text
                    style={{
                      textAlign: "center",
                      textDecoration: "underline",
                      backgroundColor: "#c5d9eb",
                      fontSize: 13,
                    }}
                  >
                    Court Record Check
                  </Text>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 10,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Details
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 10,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      As Provided
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Candidate name
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {courtEvidence?.court?.candidateName}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Fathers Name
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {courtEvidence?.court?.fatherName}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Candidate - Date of Birth
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {courtEvidence?.verificationDetails?.dateOfBirth}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Present Address
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {courtEvidence?.court?.address}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Address & ID Proof Provided
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {courtEvidence?.court?.idProof}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Date of Verification
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {courtEvidence?.court?.dateOfVerification}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Record summery
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {courtEvidence?.court?.recordSummery}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Additional Remarks
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      For detailed description, please refer to Annexure –{" "}
                      {courtEvidence?.verificationDetails?.annexure}
                    </Text>
                  </View>
                </View>
                {/* <View break /> */}
                {courtEvidence?.court?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={CourtCheckStyles.centeredImageContainer}
                      wrap={false} // Prevent image splitting between pages
                      break // Page break to place image on a new page
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          margin: "17px 0 0 0",
                          textDecoration: "underline",
                          textAlign: "center",
                          display: "block",
                          marginTop: "50px",
                          marginBottom: "20px",
                        }}
                      >
                        Annexure–{courtEvidence?.verificationDetails?.annexure}
                      </Text>
                      <AsyncImage
                        fileUrl={url}
                        alt={`Court evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
