import React, { useState, useEffect } from "react";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import IdentityDetails from "../../StaffMember/IdentityDetails.jsx";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import NoData from "../../../Helper/NoDataFound.jsx";
import { ProgressSpinner } from "primereact/progressspinner";

export default function IdentityCheck({
  userVerificationRequestInfo,
  taskDetail,
  info,
  flag,
}) {
  const [selectedStaffForAddressCheck, setSelectedStaffForAddressCheck] =
    useState(null);
  const [BCAStaffList, setBCAStaffList] = useState([]);
  const [identityCheckInfo, setIdentityCheckInfo] = useState([]);
  const [identityCheckId, setIdentityCheckId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addNewLoading, setAddNewLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [missingIdentity, setMissingIdentity] = useState([]);

  let user = JSON.parse(localStorage.getItem("user"));

  const getBCAStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.assignedChecks.length === 0 ||
            staff.assignedChecks.includes("Identity")
          );
        });
        console.log("staffListForIdentity>>", filteredData);

        setBCAStaffList(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user?.role !== "company" && user?.role !== "user") {
      if (user?.role === "BCA") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.BCAId);
      } else if (user?.role === "Vendor") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.vendorId);
      } else if (user?.role === "Coordinator") {
        getBCAStaffByBCAId(user?.BCAId);
      } else {
        getBCAStaffByBCAId(user?._id);
      }
    }
    getIndetityInfo(userVerificationRequestInfo?._id);
  }, [userVerificationRequestInfo?._id]);

  useEffect(() => {
    console.log(missingIdentity, "missingIdentity");
  }, [missingIdentity.length > 0]);

  useEffect(() => {
    if (
      identityCheckInfo.length >= 0 &&
      userVerificationRequestInfo?.checksOption?.identity?.length > 0
    ) {
      const missingQualifications = [];

      userVerificationRequestInfo.checksOption.identity.forEach((option) => {
        const normalizedOption = option;

        const found = identityCheckInfo.some(
          (check) => check.identity.type === normalizedOption
        );

        if (!found) {
          missingQualifications.push(option);
        }
      });

      setMissingIdentity(missingQualifications);

      if (missingQualifications.length > 0) {
        console.log("Missing Identity:", missingQualifications);
      }
    }
  }, [identityCheckInfo, userVerificationRequestInfo?.checksOption?.identity]);

  const getIndetityInfo = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    setLoading(true);
    await ApiService.get(`${url}/${id}`)
      .then((res) => {
        setIdentityCheckInfo(res?.data?.docs);
        setIdentityCheckId(res?.data?.docs[0]?._id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createIdentityCheck = async (IDType) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    setAddNewLoading(true);
    const body = {
      userVerificationRequestId: userVerificationRequestInfo?._id,
      identity: {
        type: IDType === "" ? "" : IDType,
        cardNumber: "",
        name: "",
        dateOfBirth: "",
        fathersName: "",
        dateOfIssue: "",
        dateOfExpiry: "",
        issueCountry: "",
        isDocAvailable: true,
        state: "",
        country: "",
        additionalRemarks: "",
        reportUrl: [],
        status: "Pending",
        remark: "",
        uploadProofUrl: [],
      },
      verificationDetails: {
        reportRemark: "",
        remarkDate: "",
        selectRemark: "",
        annexure: "",
        qualificationRemark: "",
        unversityNameRemark: "",
        unversityAddressRemark: "",
        schoolNameRemark: "",
        schoolAddressRemark: "",
      },

      location: {
        latitute: "",
        longitute: "",
      },
    };
    await ApiService.post(`${url}`, body)
      .then(() => {
        toast.success("Identity Added successfully");
        setAddNewLoading(false);
        getIndetityInfo(userVerificationRequestInfo?._id);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setAddNewLoading(false);
        console.log(err);
      });
  };
  const handleCreateTaskAPI = async () => {
    setAssignLoading(true);
    try {
      let body = {
        userVerificationRequestId: userVerificationRequestInfo._id,
        BCAId: userVerificationRequestInfo?.BCAId,
        assignedStaffMemberId: selectedStaffForAddressCheck?._id,
        status: "InProgress",
        checkListItem: [],
        checkListKey: "Identity",
      };

      if (userVerificationRequestInfo?.IdentityTaskId === null) {
        body.checkListItem = identityCheckInfo;
        await createTask(body);
      } else {
        body.checkListItem = identityCheckInfo;
        let path = `task/${userVerificationRequestInfo?.IdentityTaskId}`;
        await updateTask(path, {
          assignedStaffMemberId: selectedStaffForAddressCheck?._id,
        });
      }

      if (body.BCAId === "" || body.BCAId === undefined) {
        // toast.error("Select the BCA");
      }
    } catch (error) {
      toast.error("Error in Assign task");
      setAssignLoading(false);
      console.error("Error in API:", error);
    }
  };

  const updateBcaConsentReq = async (type) => {
    let body = {
      status: type,
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCaseIsAssigned = async () => {
    let body = {
      isAssigned: true,
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        // toast.success("Case Key Updated Successfully");
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTask = async (body) => {
    const response = await ApiService.post("task", body);
    if (response) {
      toast.success("Task created successfully");
      getIndetityInfo(userVerificationRequestInfo?._id);
      setAssignLoading(false);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  const updateTask = async (path, body) => {
    const response = await ApiService.post(path, body);
    if (response) {
      toast.success("Task updated successfully");
      getIndetityInfo(userVerificationRequestInfo?._id);
      setAssignLoading(false);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };

  useEffect(() => {}, [identityCheckId]);

  const handlePrefillData = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffList.length > 0) {
      const selectedStaff = BCAStaffList.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );
      return selectedStaff ? selectedStaff : null;
    }
  };

  const prefillAddressStaff = async () => {
    try {
      const selectedstaff = await handlePrefillData(
        userVerificationRequestInfo?.IdentityTaskId
      );

      setSelectedStaffForAddressCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchStaffForAddress = async () => {
      if (userVerificationRequestInfo?.IdentityTaskId !== null) {
        await prefillAddressStaff(userVerificationRequestInfo?.IdentityTaskId);
      }
    };

    fetchStaffForAddress();
  }, [userVerificationRequestInfo !== "" && BCAStaffList.length > 0]);

  return (
    <>
      <div>
        <div className="flex flex-col lg:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>Identity Check</h5>
          </div>

          <div className="flex gap-2 max-sm:flex-col">
            {identityCheckInfo.length > 0 &&
            (user?.role === "BCA" ||
              user?.role === "Vendor" ||
              user?.role === "Coordinator") ? (
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForAddressCheck}
                  onChange={(e) => {
                    setSelectedStaffForAddressCheck(e.value);
                  }}
                  options={BCAStaffList}
                  optionLabel="firstName"
                  className="w-[148px] h-[45px] max-sm:w-full"
                  placeholder="Assign Staff"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={identityCheckInfo.length === 0}
                />
              ) : (
                ""
              )}
            <div className="flex gap-2 max-sm:flex max-sm:justify-evenly">
              {identityCheckInfo.length > 0 &&
              (user?.role === "BCA" ||
                user?.role === "Vendor" ||
                user?.role === "Coordinator") ? (
                  <Button
                    label="Assign"
                    type="button"
                    loading={assignLoading}
                    onClick={() => handleCreateTaskAPI()}
                    className="w-[90px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                ) : (
                  ""
                )}
              {flag === "false" && (
                <Button
                  label="Add New"
                  type="button"
                  loading={addNewLoading}
                  onClick={() => createIdentityCheck()}
                  className="w-[130px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-[10px]">
          {missingIdentity.length > 0 &&
            missingIdentity.map((option, index) => (
              <Button
                key={index}
                label={option}
                onClick={() => createIdentityCheck(option)}
                type="button"
                className="w-[150px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60 mb-2"
              />
            ))}
        </div>
      </div>
      {loading ? (
        <>
          <div className="flex justify-center items-center h-screen w-full">
            <ProgressSpinner
              style={{ width: "60px", height: "60px" }}
              strokeWidth="4"
              animationDuration=".7s"
            />
          </div>
        </>
      ) : (
        <>
          {identityCheckInfo.length === 0 ? (
            <>
              <div className="flex items-center">
                <NoData info="Ooops, we could not find any identity" />
              </div>
            </>
          ) : (
            <>
              {identityCheckInfo.length > 0 &&
                identityCheckInfo !== null &&
                identityCheckId !== undefined &&
                identityCheckId !== null &&
                identityCheckInfo !== undefined && (
                <>
                  <IdentityDetails
                    userVerificationDetail={userVerificationRequestInfo?._id}
                    identityCheckId={identityCheckId}
                    identityCheckInfo={identityCheckInfo}
                    getIndetityInfo={getIndetityInfo}
                    taskDetail={taskDetail}
                    flag={flag}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
