import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const CourtCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "blue";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "blue";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  default:
    return "black";
  }
};

export const PamacCourtDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [courtInfo, setCourtInfo] = useState([]);

  const getCourtInfo = (id) => {
    if (id) {
      ApiService.get(`courtCheck/${id}`)
        .then((res) => {
          setCourtInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getCourtInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (courtInfo.length > 0) {
      onDataUpdate({
        courtInfo,
        renderCourtDetails: () => renderCourtDetails(),
      });
    }
  }, [courtInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderCourtDetails = () => {
    return (
      <>
        <Text style={{ textAlign: "center", textDecoration: "underline" }}>
          Court Verification
        </Text>
        {courtInfo?.length > 0 && (
          <>
            {courtInfo.map((courtEvidence, index) => (
              <React.Fragment key={index}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Court verification:
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      Status:
                    </Text>
                    <View
                      style={{
                        backgroundColor: getStatusColor(
                          courtEvidence?.court?.status
                        ),
                        paddingVertical: 2,
                        paddingHorizontal: 10,
                        borderRadius: 4,
                      }}
                    >
                      <Text
                        style={{
                          color: "black",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {courtEvidence?.court?.status}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* Render table on one page */}
                <View style={CourtCheckStyles.table}>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Details
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      As Provided
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      As Verified
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Name of the Candidate :-
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                      }}
                    >
                      {courtEvidence?.court?.candidateName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                      }}
                    >
                      {courtEvidence?.verificationDetails?.candidateNameRemark}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Address :-
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                      }}
                    >
                      {courtEvidence?.court?.fullAddress}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                      }}
                    >
                      {courtEvidence?.verificationDetails?.fullAddressRemark}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Courts :-
                    </Text>
                    <View style={{ flexDirection: "col" }}>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            width: "100px",
                            //   height: "200px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          Court:
                        </Text>
                        <Text
                          style={{
                            width: "100px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          Name of the court
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            width: "100px",
                            //   height: "200px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          Civil Court
                        </Text>
                        <Text
                          style={{
                            width: "100px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          {courtEvidence?.court?.civilCourt}
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            width: "100px",
                            //   height: "200px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          High Court
                        </Text>
                        <Text
                          style={{
                            width: "100px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          {courtEvidence?.court?.highCourt}
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            width: "100px",
                            //   height: "200px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          Magistrate Court
                        </Text>
                        <Text
                          style={{
                            width: "100px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          {courtEvidence?.court?.magistrateCourt}
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            width: "100px",
                            //   height: "200px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          Session Court
                        </Text>
                        <Text
                          style={{
                            width: "100px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          {courtEvidence?.court?.sessionCourt}
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            width: "100px",
                            //   height: "200px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          High Court
                        </Text>
                        <Text
                          style={{
                            width: "100px",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 10,
                            fontSize: 8,
                          }}
                        >
                          {courtEvidence?.court?.otherCourt}
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                      }}
                    >
                      {courtEvidence?.verificationDetails?.courtDetailRemark ||
                        "No Records"}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Date of search :-
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                      }}
                    >
                      {courtEvidence?.court?.dateOfSearch}
                    </Text>
                  </View>
                  <View style={CourtCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Conclusion & Additional remarks:
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 10,
                        fontSize: 8,
                      }}
                    >
                      {courtEvidence?.court?.additionalRemarks}
                    </Text>
                  </View>
                </View>
                {/* <View break /> */}
                {courtEvidence?.court?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={CourtCheckStyles.centeredImageContainer}
                      wrap={false} // Prevent image splitting between pages
                      break // Page break to place image on a new page
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          margin: "17px 0 0 0",
                          textDecoration: "underline",
                          textAlign: "center",
                          display: "block",
                          marginTop: "50px",
                          marginBottom: "20px",
                        }}
                      >
                        Copy of written verification received from{" "}
                        {courtEvidence?.court?.candidateName}
                      </Text>
                      <AsyncImage
                        fileUrl={url}
                        alt={`Court evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
