import React, { useState, useEffect } from "react";
import { Input, Row, Switch, Col, Form, Modal, Tooltip } from "antd";
import Pagination from "@mui/material/Pagination";
import { useTable, useRowSelect, useSortBy } from "react-table";
import NoData from "../../Helper/NoDataFound.jsx";
import ApiService from "../../services/APIServices";
import Stack from "@mui/material/Stack";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
// import { Dropdown } from "primereact/dropdown";
// import { MultiSelect } from "primereact/multiselect";
import CryptoJS from "crypto-js";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import DeleteIcon from "@mui/icons-material/Delete";

export const Auditor = () => {
  const [page, setPage] = useState(1);
  // const [inputValue, setInputValue] = useState();
  const [search, setSearch] = useState("");
  const [BCAInfo, setBCAInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [managerName, setManagerName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showAddVendor, setShowAddVendor] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/;
    return passwordRegex.test(password);
  };

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const encryptField = (field) => {
    if (!field) return field; // If the field is empty or null, return as is
    return CryptoJS.AES.encrypt(field, secretKey).toString();
  };

  const [form] = Form.useForm();

  let queryParams = `?limit=4&page=${page}`;
  if (search) {
    queryParams += `&name=${search}`;
  }
  const getVendorList = () => {
    let BCA = JSON.parse(localStorage.getItem("user"));
    ApiService.get(`BCAStaff/auditor/${BCA._id}${queryParams}`, {
      text: "",
    })
      .then((res) => {
        setVendorList(res?.data?.docs);
        setTotalPages(res?.data?.totalPages);
        setSpinner(false);
        // setHasVendorAccess(res?.data?.hasAccess);
      })
      .catch((error) => {
        setSpinner(false);
        console.error("Error fetching data from API:", error);
      });
  };

  const addVendor = () => {
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }
    setIsLoading(true);
    const body = {
      BCAId: BCAInfo._id,
      vendorName: vendorName,
      managerName: managerName,
      userName: userName,
      password: encryptField(password),
      email: email,
      phoneNumber: phoneNumber,
      address: address,

      role: "Auditor",
    };
    ApiService.post("BCAStaff", body)
      .then(() => {
        setIsLoading(false);
        toast.success("Audit Person Added Successfully");
        setShowAddVendor(false);
        form.resetFields();
        getVendorList();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong");
        console.log(err);
      });
  };
  useEffect(() => {
    getVendorList();
  }, [page, search]);

  const onchange = (id, newValue) => {
    ApiService.post(`BCAStaff/${id}`, { hasStaffAccess: newValue })
      .then(() => {
        setVendorList((prevData) =>
          prevData.map((item) =>
            item._id === id ? { ...item, hasStaffAccess: newValue } : item
          )
        );
        // setHasVendorAccess(newValue);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  const confirm1 = (id) => {
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      reject: () => setDialogVisible(false), // Close dialog on reject
      onHide: () => setDialogVisible(false), // Close dialog on close
    });
  };

  const accept = (id) => {
    deleteMember(id);
  };

  const deleteMember = (id) => {
    const url = `BCA/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        setDialogVisible(false);
        toast.success("Member Deleted Successfully");
        getVendorList();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Member");
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Auditor Name",
        accessor: "vendorName",
      },
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Access",
        accessor: "hasStaffAccess",
        Cell: ({ row }) => (
          <Switch
            checked={row?.original?.hasStaffAccess}
            onChange={() =>
              onchange(row?.original?._id, !row?.original?.hasStaffAccess)
            }
          />
        ),
      },
      {
        Header: "Delete",
        Cell: ({ row }) => {
          const id = row?.original?._id;
          return (
            <div
              className="cursor-pointer"
              role="presentation"
              onClick={() => confirm1(id)}
            >
              <DeleteIcon style={{ color: "red" }} />
              {/* <ConfirmDialog /> */}
            </div>
          );
        },
      },
    ],
    []
  );

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: vendorList },
    useSortBy,
    useRowSelect
  );

  const handleChange = (event, value) => {
    setPage(value);
  };
  // const handlePhoneNumberChange = (e) => {
  //   const inputNumber = e.target.value;
  //   if (/^[0-9\s-]*$/.test(inputNumber)) {
  //     setPhoneNumber(inputNumber);
  //   }
  // };
  const getUserInfo = () => {
    let BCA = JSON.parse(localStorage.getItem("user"));
    setBCAInfo(BCA);
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <>
      <div className="container mx-auto bg-white mt-6">
        <div className="flex flex-row justify-end mt-[30px] xl:mt-0 w-full">
          {BCAInfo?.role !== "Vendor" &&
          process.env.REACT_APP_VENDOR_MANAGEMENT_FEATURE_ENABLE === "true" ? (
            <>
              <Button
                label="Add Audit Person"
                icon="pi pi-plus"
                onClick={() => setShowAddVendor(true)}
                className="text-white !text-[14px] h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col mt-[20px]">
          <div className=" shadow p-[30px]  overflow-x-auto ">
            <div className="inline-block min-w-full  align-middle ">
              <div className="justify-between flex flex-row">
                {" "}
                <div className=" text-black text-[22px] font-semibold ">
                  Auditor Person&apos;s List
                </div>
                <div className="flex flex-row gap-[5px] ">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      className="h-[39px] max-sm:w-[10rem]"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Search by Name"
                    />
                  </span>
                </div>
              </div>

              <div className="w-full mt-[20px]  flex md:flex md:overflow-x-auto lg:overflow-x-visible md:rounded-lg">
                <table className="mt-2 min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-white">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="px-6 py-4"
                        key={Math.random().toString(36).substr(2, 9)}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            className="py-3.5 px-4 text-gray-400 text-sm font-medium rtl:text-right text-nowrap "
                            key={Math.random().toString(36).substr(2, 9)}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ""
                                  : ""
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                    {...getTableBodyProps()}
                  >
                    {spinner ? (
                      <tr>
                        <td colSpan={columns?.length}>
                          <div className="text-center mt-1">
                            <ProgressSpinner
                              style={{ width: "50px", height: "50px" }}
                              strokeWidth="8"
                              animationDuration=".7s"
                            />
                          </div>
                        </td>
                      </tr>
                    ) : vendorList?.length === 0 ? (
                      <tr>
                        <td colSpan={columns?.length}>
                          <NoData info="Ooops, we could not find any case" />
                        </td>
                      </tr>
                    ) : (
                      rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              const cellValue = cell.value || "";
                              const columnHeader = cell.column.render("Header");
                              return (
                                <td
                                  className="px-4 py-4 text-zinc-800 text-sm font-medium whitespace-nowrap"
                                  key={Math.random().toString(36).substr(2, 9)}
                                  {...cell.getCellProps()}
                                >
                                  {columnHeader === "User Name" ||
                                  columnHeader === "Email" ? (
                                    <Tooltip placement="top" title={cellValue}>
                                      <span>
                                        {cellValue?.substring(0, 5)}...
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-center mt-4 mb-4">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    onChange={handleChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    page={page}
                  />
                </Stack>
              </div>
            </div>
          </div>

          <Modal
            width="40%"
            title="Add Audit Person"
            open={showAddVendor}
            footer={null}
            onCancel={() => setShowAddVendor(false)}
            className="custom-modal-content modalRespo"
          >
            <Form
              layout="vertical"
              onFinish={addVendor}
              className="add-new-wallet"
              form={form}
            >
              <p className="mt-2">Let’s add Audit Person</p>
              <Row gutter={18} className="registerinfo-row">
                {/* <Col span={12}>
                  <lable>Select Checks</lable>
                  <Form.Item
                    name="checks" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Checks are required",
                      },
                    ]}
                  >
                    <Dropdown
                      // value={selectedCompany}
                      // onChange={(e) => {
                      //   setSelectedCompany(e.target.value.name);
                      //   setSelectedCompanyInfo(e.target.value);
                      //   setDiscount(e.target.value.discountPrice);
                      // }}
                      options={companyList}
                      optionLabel="name"
                      editable
                      placeholder="Select Checks"
                      filter
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col span={12}>
                  <lable>Select Client</lable>
                  <Form.Item
                    name="clientId"
                    rules={[
                      {
                        required: true,
                        message: "Client Id is required",
                      },
                    ]}
                  >
                    <Dropdown
                      value={selectedCompany}
                      onChange={(e) => {
                        setSelectedCompany(e.target.value);
                      }}
                      options={companyList}
                      optionLabel="name"
                      editable
                      placeholder="Select Company/Client"
                      filter
                    />
                    <MultiSelect
                      value={selectedCompany}
                      options={companyList}
                      onChange={(e) => {
                        setSelectedCompany(e.value);

                        const selectedCompanyId = e.value.map((ids) => ids._id);
                        setCompanyArray(selectedCompanyId);
                      }}
                      optionLabel="name"
                      // placeholder="Select Type of Checks"
                      itemTemplate={checksTemplate}
                      className="w-full md:w-20rem"
                      display="chip"
                      filter
                    />
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={18} className="registerinfo-row">
                <Col span={12}>
                  <lable>Auditor Name</lable>
                  <Form.Item
                    name="vendorName" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Auditor name is required",
                      },
                    ]}
                  >
                    <Input
                      value={vendorName}
                      placeholder="Enter Auditor name"
                      size="large"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setVendorName(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s-]$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <lable>Manager Name</lable>
                  <Form.Item
                    name="managerName" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Manager name is required",
                      },
                    ]}
                  >
                    <Input
                      value={managerName}
                      placeholder="Enter Manager Name"
                      size="large"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setManagerName(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s-]$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={18} className="registerinfo-row">
                <Col span={12}>
                  <lable>Email</lable>
                  <Form.Item
                    name="email" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                    ]}
                  >
                    <Input
                      value={email}
                      size="large"
                      placeholder="Enter Email Address"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z0-9\s@._-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setEmail(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z0-9\s@._-]*$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <lable>Phone Number</lable>
                  <Form.Item
                    name="phoneNumber" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Phone number is required",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid phone number!",
                      },
                    ]}
                  >
                    <Input
                      value={phoneNumber}
                      size="large"
                      placeholder="Enter Phone No"
                      maxLength={10}
                      // onChange={handlePhoneNumberChange}
                      onChange={(e) => {
                        const regex = /^[0-9\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setPhoneNumber(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[0-9\s-]*$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={18} className="registerinfo-row">
                <Col span={24}>
                  <label htmlFor="state">Full address</label>
                  <Form.Item
                    name="address" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Adress is required",
                      },
                    ]}
                  >
                    <Input
                      value={address}
                      placeholder="Enter State , City , Pincode"
                      size="large"
                      // onChange={(e) => {
                      //   setState(e.target.value);
                      // }}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setAddress(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s-]*$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={18} className="registerinfo-row">
                <Col span={12}>
                  <label htmlFor="userName">User Name (Required)</label>
                  <Form.Item
                    name="userName" // Unique name for the field
                    rules={[
                      {
                        required: false,
                        message: "name is required",
                      },
                    ]}
                  >
                    <Input
                      value={userName}
                      placeholder="Enter User Name"
                      size="large"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z0-9\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setUserName(newValue);
                        }
                        // setUserName(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z0-9\s-]*$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label htmlFor="password">Password (Required)</label>
                  <Form.Item
                    name="password" // Unique name for the field
                  >
                    <Password
                      value={password}
                      placeholder="Enter Password"
                      size="large"
                      toggleMask
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="w-full h-[40px]"
                    />
                    {passwordError && (
                      <div style={{ color: "red" }}>{passwordError}</div>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div className="mt-[12px] text-center">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Wait...
                    </>
                  ) : (
                    "Add Audit Person"
                  )}
                </button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <ConfirmDialog visible={dialogVisible} />
    </>
  );
};
// export default CoordinatorList;
